import React, { useState } from "react";
import { Form, InputNumber, Button, Select, message } from 'antd';

import { useSelector } from "react-redux";
import { authPostData } from '../../service'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function DeductionExpenseForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [btnLoading, setBtnLoading] = useState(false)

    const onFinish = values => {
        let url = `/api/deduction/expense`

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
                form.resetFields()
                props.onFinished()
            }else{
                message.error(data.msg);
            }
            setBtnLoading(false)

        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }
            setBtnLoading(false)
        })
    };

    return (
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item name="amount" label="年费金额" rules={[{ required: true, message:"请填写年费金额"}]}>
                <InputNumber />
            </Form.Item>
            <Form.Item name="user_id" label="用户" rules={[{ required: true, message: "用户必填" }]}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                >
                    {props.data.users.map(u =>
                        <Select.Option key={u.username} value={u.id}>{u.username}</Select.Option>
                    )}
                </Select>
            </Form.Item>
            
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={btnLoading}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}