import React, { useState, useEffect } from "react";
import { Form, Button, message, Select, InputNumber  } from 'antd';
import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function RelationForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [btnLoading, setBtnLoading] = useState(false)
    const url = `/api/user_to_salesman`
    const [salesmanList, setSalesmanList] = useState([])
    const onFinish = values => {
        setBtnLoading(true)

        authPostData(url, user.token, {
            ...values,
            id: props.data.id
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
                let item = salesmanList.find((v, index) => {
                    return v.id == values.salesman_id
                })
                props.onFinished({
                    salesman: item.username,
                })
            }else{
                message.error(data.msg);
            }

            setBtnLoading(false)
            return data
        })
        .catch((p)=>{
            p.then(data=>{
                message.error(data.message);
            })

            if(!p){
                message.error('服务器出错');
            }

            setBtnLoading(false)
        })
    };

    useEffect(() => {
        authGetData('/api/all_salesman', user.token)
            .then(data => {
                setSalesmanList(data.items)
            })
    }, [user.token])
    
    useEffect(() => {
        let item = salesmanList.find((v, index) => {
            return v.username == props.data.salesman
        })
        if(item != null){
            form.setFieldsValue({
                salesman_id: item.id,
                opening_fee: props.data.opening_fee
            })
        }else{
            form.setFieldsValue({
                salesman_id: null,
                opening_fee: 0
            })
        }
    }, [form, props.data, salesmanList])

    return (
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item label="需要关联的用户">
                {props.data.username}
            </Form.Item>
            <Form.Item name="salesman_id" label="业务员" style={{ marginBottom: 16, marginTop: 16 }} rules={[{ required: true, message: "请选择一个业务员" }]}>
                <Select allowClear placeholder="选择一个业务员">
                    {salesmanList.map(u =>
                        <Select.Option key={u.username} value={u.id}>{u.username}</Select.Option>
                    )}
                </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={btnLoading}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}