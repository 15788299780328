import 'braft-editor/dist/index.css'
import React, { useEffect } from 'react'
import BraftEditor from 'braft-editor'
import { Form, Input, Button, message, Switch } from 'antd'
import { authGetData, authPostData } from '../service'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const controls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link', 'separator']
export function Post(params) {
    const user = useSelector(state=>state.user)
    const location = useLocation()
    const [form] = Form.useForm();

    useEffect(()=>{
        const post_id = location.search.split('=')[1]
        if(post_id != null){
            authGetData(`/api/post/${post_id}`, user.token)
            .then(reps_data => {
                form.setFieldsValue({
                    title: reps_data.title,
                    content: BraftEditor.createEditorState(reps_data.content)
                })
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.msg)
                })
            })
        }
    }, ['location'])

    const onFinish = (values) => {
        const post_id = location.search.split('=')[1]

        let data = {
            ...values,
            content: values.content.toHTML()
        }

        console.log(data.is_hide == null)

        if(data.is_hide == null){
            data.is_hide = false
        }

        authPostData(`/api/post${post_id?'/'+post_id:''}`, user.token, {
            ...data
        })
            .then(reps_data => {
                message.success(reps_data.msg)
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.msg)
                })
            })
    }

    return (
        <div className="demo-container">
            <Form form={form} layout="vertical" onFinish={onFinish} style={{maxWidth:900}}>
                <Form.Item name="title" label="文章标题" rules={[{ required: true, message: '标题不能为空' }]}>
                    <Input size="large" placeholder="请输入标题" />
                </Form.Item>
                <Form.Item name="content" label="文章正文" rules={[
                    { 
                        required: true, 
                        message: '请输入正文内容',
                        validator: (_, value) => {
                            return !value.isEmpty() ? Promise.resolve() : Promise.reject('正文内容不能为空')
                        }
                    },
                ]}>
                    <BraftEditor
                        className="my-editor"
                        controls={controls}
                        placeholder="请输入正文内容"
                    />
                </Form.Item>
                <Form.Item name="is_hide" label="是否隐藏">
                    <Switch checkedChildren="隐藏" unCheckedChildren="显示(默认)" />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit">提交</Button>
                </Form.Item>
            </Form>
        </div>
    )

}