import { message } from 'antd';
import React, { useEffect } from 'react'
import PotentialForm from "./forms/PotentialForm";

export default function(){
    useEffect(()=>{
        document.getElementById("viewport").setAttribute("content", "width=device-width; initial-scale=1");
    }, [])

    const onFinished = (data) => {
        if (data.status === 'success') {
            message.success(data.msg)
        } else {
            message.info('抱歉, 网络错误, 请长按图片, 添加微信了解')
        }
    };

    return (
        <div>
            <img style={{ width: '100%' }} alt="logo" src={process.env.PUBLIC_URL + '/leaflet.png'} />
            <div style={{padding: 16}}>
                <PotentialForm onFinished={onFinished} />
            </div>
        </div>
    )
}