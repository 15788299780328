import React, { useState } from "react";
import { Form, Input, Button, message, Row, Col, notification, DatePicker, Alert, Select } from 'antd';
import { authPostData } from '../../service';
import { useSelector } from "react-redux";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import 'moment/locale/zh-cn';

const { RangePicker } = DatePicker;

const messages = {
    'location': "[最新位置查询]：每车单次扣费0.5元、上限1元/天",
    'line': "[轨迹查询]：每车单次扣费1元, 上限1元/天",
    'innet': "[车辆入网验证]：按车牌号判断指定车辆是否在全国货运平台入网服务, 免费 上限2000次/天",
    'exist': "[车辆确认]：判断车辆是否在全国道路货运车辆公共监管与服务平台入网, 每车单次扣费0.2元",
    'vehicle_license': "[行驶证信息查询]根据车牌号、车牌颜色查询车辆行驶证信息, 每车单次扣费1元",
    'trans_license': "[道路运输证]根据车牌号、车牌颜色查询道路运输证号码及有效期等信息, 免费 上限2000次/天",
    'owner': "[车主真实性验证]根据车牌号、车主姓名及车主电话，验证车主真实性。1-1000次 免费,1000-5000次 0.5元/次, 5000-1000次 0.4元/次, 10000次以上 0.3元/次",
}

export default function TrackingSearchForm(props) {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState(false)
    const user = useSelector(state => state.user)
    const location = useLocation()
    const tracking_type = location.pathname.match(/^\/tracking\/(\w+)$/)[1]

    const onFinish = values => {
        let fieldsValue = {
            ...values,
        }

        if (!/^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/.test(values.plate.trim())){
            message.error('车牌号格式不正确')
            return
        }

        if(tracking_type === 'line'){
            if (values.time[1] - values.time[0] === 0) {
                message.error('开始时间与结束时间不能相同')
                return
            }
            
            fieldsValue['qryBtm'] = values.time[0].format('YYYY-MM-DD HH:mm:ss')
            fieldsValue['qryEtm'] = values.time[1].format('YYYY-MM-DD HH:mm:ss')
        }

        setBtnLoading(true)

        authPostData(`/api/tracking/${tracking_type}`, user.token, {
            ...fieldsValue
        })
        .then(data=>{
            setBtnLoading(false)

            if (data.status === 'success') {
                message.success(data.msg);

                if (tracking_type === 'location'){
                    if(!data.result) return;
                    notification.success({
                        message: '查询结果',
                        description: <div><p>地址: {data.result.adr}</p> <p>最后定位时间: {moment(Number(data.result.utc)).format("YYYY-MM-DD hh:mm:ss")}</p></div>,
                        duration: 0
                    })
                }

                if (tracking_type === 'owner') {
                    if (!data.result) return;
                    notification.success({
                        message: '查询结果',
                        description: <div> {data.result === 'yes' ? '车牌号与车主匹配' : `${data.result==='name'?'姓名不匹配':'电话不匹配'}`}</div>,
                        duration: 0
                    })
                }

                if (typeof props.onFinish == 'function') {
                    if(data.result){
                        props.onFinish(data.result)
                    }
                }
            } else {
                message.error(data.msg);
            }
        })
        .catch(p => {
            if (user.token === '') return
            if(!p){
                message.error('服务器错误')
                return
            }
            console.log(p);
            // p.then(data => {
            //     message.error(data.message)
            // }).catch(error => {
            //     console.log('error', error)
            // })
        })
    };
    

    return (
        <>
            <Form form={form} onFinish={onFinish} style={{
                maxWidth: '100%',
                paddingBottom: '16px'}}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item name="plate" label="车牌号" style={{ marginBottom: 0 }} rules={[{ required: true, message: "请填写车牌号" }]}>
                            <Input placeholder="请输入车牌号" allowClear />
                        </Form.Item>
                    </Col>
                    {tracking_type==='line' &&
                        <Col span={9}>
                            <Form.Item name="time" label="起止时间" style={{ marginBottom: 0 }} rules={[{ required: true, message: "请选择起止时间, 开始时间和结束时间不能超过24小时" }]}>
                                <RangePicker 
                                    locale={locale}
                                    showTime />
                            </Form.Item>
                        </Col>
                    }
                    {(tracking_type === 'vehicle_license' || tracking_type === 'trans_license') &&
                        <Col span={6}>
                            <Form.Item name="color" initialValue="2" label="车牌颜色" style={{ marginBottom: 0 }} rules={[{ required: true, message: "请选择车牌颜色" }]}>
                                <Select>
                                    <Select.Option key="c1" value="1">蓝色</Select.Option>
                                    <Select.Option key="c2" value="2">黄色</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {tracking_type === 'owner' &&
                        <Col span={6}>
                            <Form.Item name="ownerName" label="车主姓名" style={{ marginBottom: 0 }} rules={[{ required: true, message: "车主姓名" }]}>
                                <Input placeholder="请输入车主姓名" allowClear />
                            </Form.Item>
                        </Col>
                    }
                    {tracking_type === 'owner' &&
                        <Col span={6}>
                        <Form.Item name="ownerPhone" label="车主电话(非必填)" style={{ marginBottom: 0 }}>
                                <Input placeholder="请输入车主电话" allowClear />
                            </Form.Item>
                        </Col>
                    }
                    <Col span={6}>
                        <Button type="primary" htmlType="submit" loading={btnLoading}>查询</Button>
                        <Button
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            清空
                        </Button>
                    </Col>

                </Row>
                <Row gutter={24}>
                </Row>
            </Form>
            <Alert
                style={{margin:"10px 0"}}
                message={messages[tracking_type]}
                type="warning"
                closable
            />
        </>
    )
}