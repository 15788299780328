import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { routes } from '../routes';
import { setsite } from '../redux/actions';

export default function useActiveBar(setSeletedKey) {
    let location = useLocation();

    const dispatch = useDispatch()

    useEffect(() => {
        const found = routes.find(el => el.path === location.pathname)
        if (found) {
            setSeletedKey(found.key)
            if (found.path.indexOf('/insurance') !== -1) {
                dispatch(setsite('insurance'))
            } else {
                dispatch(setsite('etc'))
            }
        }
    }, [location])
    
}