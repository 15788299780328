import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { login, fetching } from './redux/actions'
import { getData } from './service'
import { Spin, Result, Button } from 'antd';
import { Base } from './components/Base'
import { routes } from './routes'
import './App.css'
import LandingPage from "./components/Landing";
import LoginPage from "./components/Login";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Leaflet from "./components/Leaflet";
import Avatar from "antd/lib/avatar/avatar";

function PrivateRoute(props){
  useRouteMatch({
    path:props.path
  })
  let user = useSelector(state=>state.user)
  let history = useHistory()
  let location = useLocation()
  let dispatch = useDispatch()
  
  useEffect(()=>{
    if(user.token === ''){
      dispatch(fetching({isFetching: true}))
      getData('/api/refresh_token')
        .then(data=>{
          if(data.status === 'success'){
            dispatch(login(data))
          }
        })
        .catch((err_promise)=>{
          history.replace('/login', { from: location })
        })
        .finally(()=>{
          dispatch(fetching({isFetching:false}))
        })
    }
  },[])

  return user.isFetching ?
    <Spin /> :
    (user.token!=null && props.children)
}

export default function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route path='/' exact>
          <LandingPage></LandingPage>
        </Route>
        <Route path='/x' exact>
          <Leaflet></Leaflet>
        </Route>
        <Route path='/login'>
          <LoginPage></LoginPage>
        </Route>
        <Route path='/404'>
            <Result
              title="404"
              subTitle="你访问的页面不存在."
              icon={<Avatar size={400} src="https://img.56httx.com/zy/404.png" />}
              extra={<Button type="primary"><Link to="/">返回首页</Link></Button>}
            />
        </Route>
        <Base>
          <Switch>
          {routes.map((route, index) => (
            route.isPrivate &&
              <PrivateRoute key={index} path={route.path}>
                {route.sidebar()}
              </PrivateRoute>
          ))}
          <Route path="*">
            <Redirect to='/404'></Redirect>
          </Route>
          </Switch>
        </Base>
      </Switch>
    </Router>
   
    </>
  );
}