import React, {  useEffect, useState } from 'react'
import { Table, Tag, message, Popconfirm, Button } from 'antd'
import { authGetData, authDeleteData } from '../service';
import { useSelector } from 'react-redux';


export default function VehiclePage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const user = useSelector(state=>state.user)

    let handleTableChange = (pagination)=>{
        setPage(pagination.current)
    }

    let handlerDelete = (id, index)=>{
        authDeleteData(`/api/vehicle/${id}`, user.token)
            .then(reps_data=>{
                let _data = data.slice()
                _data.splice(index, 1)
                message.success(reps_data.msg)
                setData(_data)
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '所属人',
            dataIndex: 'username',
            width: '20%',
        },
        {
            title: '车牌号',
            dataIndex: 'plate_number',
            width: '20%',
        },
        {
            title: '车辆颜色',
            dataIndex: 'plate_color',
            render: (text, record) => {
                if (record.plate_color === '1') {
                    return '黄色'
                }
            }
        },
        {
            title: '状态码',
            dataIndex: 'code',
            width: '20%',
            render: (code)=>{
                return (
                    <Tag color='geekblue' key={code}>
                        {(code===0||code===10030)?'已备案':code}
                    </Tag>
                )
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => 
                { user.is_admin &&
                    <Popconfirm title="Are you sure？" okText="确定" cancelText="取消" onConfirm={handlerDelete.bind(this, record.id, index)}>
                    <Button type="link" size="small" danger>删除</Button>
                    </Popconfirm>
                }
            
        },
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const pagination = {
        total: total,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    }

    useEffect(()=>{
        setLoading(true)
        authGetData(`/api/vehicles/page/${page}`, user.token)
            .then(data=>{
                setLoading(false)
                setData(data.items)
                setTotal(data.total)
            })
            .catch(p=>{
                if (user.token === '') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
    }, [page, user.token]);


    return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
        />
    )
}