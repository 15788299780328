import React, { useState } from "react";
import { Form, InputNumber, Button, Typography, message, Divider, Radio, Modal, notification, Row, Col, Space} from 'antd';

import { useSelector } from "react-redux";
import { authGetData, authPostData } from '../../service'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { createFromIconfontCN } from '@ant-design/icons';
import useInterval from "../../hooks/useInterval";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2316353_u37sh1tvzmk.js',
});

const { Title, Paragraph } = Typography;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


export default function SelfTopupForm(props){
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const history = useHistory()
    const user = useSelector(state=>state.user)
    const [btnLoading, setBtnLoading] = useState(false)
    const [btnLoading2, setBtnLoading2] = useState(false)
    const [currentValue, setCurrentValue] = useState(null)
    const [currentRadioValue, setCurrentRaduiValue] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [qrcode, setQrcode] = useState(null)
    const [outTradeNo, setOutTradeNo] = useState(null)
    const onFinish = values => {
        let url = `/api/user/topup`

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
                setQrcode(data.path)
                setOutTradeNo(data.out_trade_no)
                setIsModalVisible(true)
                form.resetFields()
            }else{
                message.error(data.msg);
            }
            setBtnLoading(false)

        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }
            setBtnLoading(false)
        })
    };

    const onFinish2 = values => {
        let url = `/api/transfer_record`
        setBtnLoading2(true)

        authPostData(url, user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
            }else{
                message.error(data.msg);
            }
            form2.resetFields()
            setBtnLoading2(false)

        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }
            setBtnLoading2(false)
        })
    };

    useInterval(() => {
        handleOk()
    }, isModalVisible ? 2000 : null)

    const onChange = (e)=> {
        form.setFieldsValue({
            amount:e.target.value
        })
    }

    const getNum = (num)=> {
        setCurrentRaduiValue(null)
    }

    const handleOk = (e) => {
        if (outTradeNo == null) return
        authGetData(`/api/user/paystate/${outTradeNo}`, user.token)
            .then(data => {
                if (data.status === 'success' && data.pay_status == 1) {
                    //支付成功
                    notification.success({
                        duration: null,
                        message: '消息提醒',
                        description: data.msg
                    })
                    setIsModalVisible(false)
                    form.resetFields()
                    history.push('/dashboard')
                }
            })
    }

    const handleCancel = (e) => {
        setIsModalVisible(false)
    }

    return (
        <>
            <Typography>
                <Title level={3}>充值</Title>
                <Paragraph>
                    支持微信支付和公转公
                </Paragraph>
            </Typography>
            <Divider></Divider>
            <Row>
                <Col span={12}>
                    <Space>
                        <IconFont type="iconweixinzhifu" style={{fontSize:20}} />
                        微信支付
                    </Space>
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                        <Form.Item label="充值金额">
                            <Radio.Group onChange={onChange} value={currentRadioValue}>
                                <Radio.Button value="1000">1000元</Radio.Button>
                                <Radio.Button value="2000">2000元</Radio.Button>
                                <Radio.Button value="5000">5000元</Radio.Button>
                                <Radio.Button value="10000">10000元</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="amount" rules={[{ required: true, message: '充值金额不能为空' }]} {...tailLayout}>
                            <InputNumber 
                                placeholder="自定义充值金额" 
                                onChange={getNum} 
                                value={currentValue} 
                                min="0.01" 
                                max="50000" 
                                size="large" 
                                style={{ width: 200}} 
                            />
                        </Form.Item>
                        
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" loading={btnLoading}>
                                微信充值
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                    <Space>
                        <IconFont type="iconyinhangka" style={{ fontSize: 30 }} />
                        公对公打款
                    </Space>
                    <Paragraph>
                        <pre>
                            <div>公司名称：常州甲保御网络科技有限公司</div>
                            <div>开户行 ：兴业银行常州分行营业部</div>
                            <div>银行账号：406010100100725246</div>
                            <div>用途: 服务费</div>
                        </pre>
                    </Paragraph>
                    <Form layout="inline" form={form2} name="control-hooks" onFinish={onFinish2}>
                        <Form.Item name="amount" rules={[{ required: true, message: '打款金额不能为空' }]}>
                            <InputNumber
                                placeholder="打款金额"
                                min="0.01"
                                max="50000"
                                style={{width: 200}}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={btnLoading2}>
                                确认打款
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Divider></Divider>
            <Modal maskClosable={false} keyboard={false}  title="微信扫描二维码支付" visible={isModalVisible} cancelText="取消" okText="支付完成" onOk={handleOk} onCancel={handleCancel}>
                <div style={{textAlign:"center"}}>
                    <img alt="pay" src={qrcode} />
                </div>
            </Modal>
        </>
    )
}