import React, { useEffect, useState } from 'react'
import { Table, message, Tag, Button, Drawer, Descriptions } from 'antd'
import { authGetData } from '../../service';
import { useSelector } from 'react-redux';

export default function InsuranceOrdersPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState(null)
    const user = useSelector(state=>state.user)
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    const onClose = () => {
        setVisible(false)
    };

    const setInsuranceOrder = (id) => {
        console.log(id)
        authGetData(`/api/insurance/order/${id}`, user.token)
            .then(data => {
                if (data.status === 'success'){
                    setOrder(data.data)
                    setVisible(true)
                }else{
                    message.error(data.msg)
                }
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const columns = [
        {
            title: '系统订单号',
            dataIndex: 'channelOrderNo',
        },
        {
            title: '运单号',
            dataIndex: 'freightNo',
        },
        {
            title: '被保人',
            dataIndex: 'insureName',
        },
        {
            title: '保额',
            dataIndex: 'coverage',
        },
        {
            title: '保费',
            dataIndex: 'pay_money',
        },
        {
            title: '车牌号',
            dataIndex: 'carNo',
        },
        {
            title: '保费',
            dataIndex: 'pay_money',
        },
        {
            title: '货物明细',
            dataIndex: 'goodsName',
        },
        {
            title: '投保时间',
            dataIndex: 'InsuranceTime',
        },
        {
            title: '保单状态',
            dataIndex: 'status',
            render: (status, row) => {
                let color = '';
                let text = ''
                if(status === 1){
                    color = 'blue'
                    text = '已生效'
                }else if(status === 2){
                    color = 'magenta'
                    text = '已作废'
                }else if(status === 0 && row['payed'] === 0){
                    color = 'magenta'
                    text = '已作废'
                }else{
                    text = '待生成'
                }

                return (
                    <Tag color={color}>
                        {text}
                    </Tag>
                )
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (row) => (
                <>
                    <Button type="primary" onClick={setInsuranceOrder.bind(this, row.id)}>查看详情</Button>
                    {row.doc_url && 
                        <Button type="link"><a target="_blank" rel="noopener noreferrer" href={row.doc_url}>下载保单</a></Button>
                    }
                </>
            )
        },
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    useEffect(()=>{
        setLoading(true)
        authGetData(`/api/insurance/orders/page/${pagination.current}`, user.token)
            .then(data=>{
                if(data == null) return;
                setLoading(false)
                setData(data.items)
                setPagination({
                    ...pagination,
                    total: data.total
                })
            })
            .catch(p=>{
                if(user.token==='') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
    }, [pagination.current, user.token]);

    return (
        <>
            <Table
                scroll={{ x: 1300 }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Drawer
                width={800}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                {order != null &&
                <Descriptions
                    title="保单详情"
                    bordered
                    column={2}
                >
                    <Descriptions.Item label="流水号">{order.channelOrderNo}</Descriptions.Item>
                    <Descriptions.Item label="保单状态">{order.status_name}</Descriptions.Item>
                    <Descriptions.Item label="运单号/发票号	">{order.freightNo}</Descriptions.Item>
                    <Descriptions.Item label="投保单号">{order.policy_number}</Descriptions.Item>
                    <Descriptions.Item label="投保人">{order.holderName}</Descriptions.Item>
                    <Descriptions.Item label="被保人">{order.insureName}</Descriptions.Item>
                    <Descriptions.Item label="投保人统一社会信用代码">{order.holderCertNo}</Descriptions.Item>
                    <Descriptions.Item label="被保人统一社会信用代码">{order.insureCertNo}</Descriptions.Item>
                    <Descriptions.Item label="起运日期">{order.expectStartTime}</Descriptions.Item>
                    <Descriptions.Item label="到达日期">{order.expectEndTime}</Descriptions.Item>
                    <Descriptions.Item label="车牌号">{order.carNo}</Descriptions.Item>
                    <Descriptions.Item label="承运人">{order.deliveryNo}</Descriptions.Item>
                    <Descriptions.Item label="货物大类">{order.big_name}</Descriptions.Item>
                    <Descriptions.Item label="货物小类">{order.small_name}</Descriptions.Item>
                    <Descriptions.Item label="起运地">{order.departure}</Descriptions.Item>
                    <Descriptions.Item label="目的地">{order.destination}</Descriptions.Item>
                    <Descriptions.Item label="货物名称/描述">{order.goodsName}</Descriptions.Item>
                </Descriptions>
                }
                {order != null &&
                <Descriptions
                    bordered
                    column={2}
                    style={{marginTop:20}}
                >
                    <Descriptions.Item label="保险公司">{order.company}</Descriptions.Item>
                    <Descriptions.Item label="险种">{order.product_name}</Descriptions.Item>
                    <Descriptions.Item label="保险金额">{order.coverage}元</Descriptions.Item>
                    <Descriptions.Item label="保费">{order.pay_money}元</Descriptions.Item>
                    <Descriptions.Item label="免赔及特别约定" span={2}>
                        <div style={{width:500}} dangerouslySetInnerHTML={{ __html: order.outlined }}></div>
                    </Descriptions.Item>
                    <Descriptions.Item label="投保时间">{order.InsuranceTime}</Descriptions.Item>
                    {order.doc_url &&
                        <Descriptions.Item label="下载保单"><Button type="primary"><a target="_blank" rel="noopener noreferrer" href={order.doc_url}>下载保单</a></Button></Descriptions.Item>
                    }
                </Descriptions>
                }
            </Drawer>
        </>
    )
}