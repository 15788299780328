import React from "react";
import { Form, Input, Button, message } from 'antd';
import { useSelector } from "react-redux";
import { authPostData } from '../../service'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function VehicleBlockForm(){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const onFinish = values => {
        console.log(values);
        authPostData('/api/vehicle/block', user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
            }else{
                message.error(data.msg);
            }
        })
        .catch(p => {
            p.then(data => {
                message.error(data.message)
            })

            if (!p) {
                message.error('服务器出错');
            }
        })
    };

    return (
        <Form {...layout} initialValues={{plate_color:"1"}} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item name="plate_number" label="车牌号" rules={[{ required: true, message:"请填写车牌号"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="reason" label="黑名单原因" rules={[{ required: true, message:"请填写原因"}]}>
                <Input />
            </Form.Item>
            
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}