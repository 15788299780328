import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select, Upload, Checkbox } from 'antd';
import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service'
import { UploadOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function TaxnoForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [users, setUsers] = useState([])

    useEffect(() => {
        if (user.is_admin || user.is_salesman){
            authGetData('/api/users', user.token)
                .then(data => {
                    setUsers(data.items)
                })
        }
    }, [user.token])

    const onFinish = values => {
        
        let url = '/api/taxno'
        
        let data = {}
        
        if(props.data != null){
            url = `/api/taxno/${props.data.id}`
            data = {
                ...values
            }
        }else{
            data = {
                ...values,
                business_license: values.business_license!=null?values.business_license[0].response.file_name:null,
                account_license: values.account_license!=null?values.account_license[0].response.file_name:null,
                phone_number: values.phone_number.replace(/[-\s]/g, '')
            }
        }

        authPostData(url, user.token, data).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
                form.resetFields()
                if(typeof props.onFinished == 'function'){
                    props.onFinished(values)
                }
            }else{
                message.error(data.msg);
            }

        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }
        })
    };

    useEffect(()=>{
        form.setFieldsValue({
            ...props.data
        })
    },[props.data, form])

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        
        return e && e.fileList;
    };

    const upload_props = {
        action: '/api/upload/license',
        accept: '.jpg,.jpeg,.png',
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
        onChange(info) {
            if (info.file.status === 'done') {
                if (info.file.response.status === 'success') {
                    message.success(info.file.response.msg);
                } else {
                    message.error(info.file.response.msg);
                }

            } else if (info.file.status === 'error') {
                message.error(`文件${info.file.name}, 上传失败, ${info.file.response.message}`);
            }

        },
    };

    return (
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item name="company_name" label="企业名称" rules={[{ required: true, message:"请填写企业名称"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="taxno" label="税号" rules={[{ required: true, message:"请填写税号"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="address" label="地址" rules={[{ required: true, message:"请填写地址"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="phone_number" label="电话" rules={[{ required: true, message:"请填写电话"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="bank_name" label="开户行" rules={[{ required: true, message:"请填写开户行"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="bank_number" label="银行账号" rules={[{ required: true, message:"请填写银行账号"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="contact_name" label="联系人" rules={[{ required: true, message:"请填写联系人"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="contact_phone_number" label="联系电话" rules={[{ required: true, message:"请填写联系电话"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="user" label="用户" style={{ marginBottom: 0, marginTop: 16 }}>
                <Select 
                    allowClear 
                    showSearch
                    placeholder="选择一个用户" 
                    filterOption={(input, option) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {users.map(u =>
                        <Select.Option key={u.username} value={u.id}>{u.username}</Select.Option>
                    )}
                </Select>
            </Form.Item>
            {props.data == null &&
            <Form.Item
                name="business_license"
                label="营业执照"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                style={{ marginBottom: 0, marginTop: 16 }}
                
            >
                <Upload name="file" listType="picture" {...upload_props}>
                    <Button icon={<UploadOutlined />}>点击上传营业执照</Button>
                </Upload>
            </Form.Item>
            }
            {props.data == null &&
            <Form.Item
                name="account_license"
                label="开户许可证"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                style={{ marginBottom: 0, marginTop: 16 }}
                
            >
                <Upload name="file" listType="picture" {...upload_props}>
                    <Button icon={<UploadOutlined />}>点击上传开户许可证</Button>
                </Upload>
            </Form.Item>
            }
            {props.data == null && user.is_admin &&
            <Form.Item name="is_marked" valuePropName="checked" {...tailLayout} style={{ marginTop: 20 }}>
                <Checkbox>只保存在智运</Checkbox>
            </Form.Item>
            }
            <Form.Item {...tailLayout} style={{marginTop:20}}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>

        </Form>
    )
}