import React, { useEffect, useState } from "react";
import { Form, InputNumber, Button, Select, Input, Row, Col, message } from 'antd';

import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service'
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DeductionExpenseForm from "./DeductionExpenseForm";
import Modal from "antd/lib/modal/Modal";
import useInterval from "../../hooks/useInterval";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function TopupForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [users, setUsers] = useState([])
    const [countDown, setCountDown] = useState(30)
    const [isRunning, setIsRunning] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const location = useLocation()

    const onFinish = values => {
        let url = `/api/topup/${values.user}`

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
                form.resetFields()
            }else{
                message.error(data.msg);
            }
            setBtnLoading(false)

        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }
            setBtnLoading(false)
        })
    };

    useEffect(() => {
        authGetData('/api/users', user.token)
            .then(data => {
                setUsers(data.items)
            })
    }, [user.token])
    
    useEffect(() => {
        // 给某个用户充值,默认选中用户
        if(location.state == null) return;
        form.setFieldsValue({
            user: location.state.id
        })
        getUserRate(location.state.id)
        
    }, [location.state, form])

    useInterval(()=>{
        if(countDown<=1){
            setCountDown(30)
            setIsRunning(false)
            return
        }
        setCountDown(countDown - 1)
    }, isRunning?1000:null)

    const getCode = ()=>{
        setIsRunning(true)
        authGetData('/api/code', user.token)
            .then(data => {
                if (data.status === 'success') {
                    message.success(data.msg);
                } else {
                    message.error(data.msg);
                }
            })
            .catch((p) => {
                p.then(data => {
                    message.error(data.message);
                })

                if (!p) {
                    message.error('服务器出错');
                }
            })
    }

    const initialValues = {
        per_transaction: 1.8,
        tracking_discount: 1
    }

    const onFinished = ()=>{
        setVisible(false)
    }

    const getUserRate = (user_id)=> {
        authGetData(`/api/user/transaction/${user_id}`, user.token)
            .then(data => {
                form.setFieldsValue({
                    per_transaction: data.per_transaction
                })
            })
    }

    return (
        <>
            <Form {...layout} form={form} name="control-hooks" initialValues={initialValues} onFinish={onFinish}>
                <Form.Item name="amount" label="充值金额" rules={[{ required: true, message:"请填写充值金额"}]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item name="per_transaction" label="ETC每笔扣费(元)" rules={[{ required: true }]}>
                    <InputNumber
                        min={0}
                        max={100}
                    />
                </Form.Item>
                <Form.Item name="tracking_discount" label="轨迹查询折扣(0-1)" rules={[{ required: true }]} extra="举例:1表示没有折扣,0.5表示5折">
                    <InputNumber
                        min={0}
                        max={1}
                    />
                </Form.Item>
                <Form.Item name="user" label="用户" rules={[{ required: true, message: "需要充值的用户" }]}>
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        onChange={getUserRate}
                    >
                        {users.map(u =>
                            <Select.Option key={u.username} value={u.id}>{u.username}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label="验证码(3分钟有效)" >
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="code"
                                noStyle
                                rules={[{ required: true, message: '请输入短信验证码' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Button onClick={getCode} disabled={isRunning}>{isRunning?`${countDown}秒后重新获取`:'获取验证码'}</Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={btnLoading}>
                        提交
                    </Button>
                </Form.Item>
            </Form>
            <Button type='link' onClick={() => setVisible(true)}>需要扣软件年费?</Button>
            <Modal
                title="扣软件年费表单"
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                <DeductionExpenseForm data={{ users: users }} onFinished={onFinished}></DeductionExpenseForm>
            </Modal>
        </>
    )
}