import React, { useEffect, useState, useRef } from 'react';
import { Layout, Breadcrumb, Button, Menu, Dropdown, Tag, Space, Modal, Badge, Tooltip } from 'antd';
import SideBar from './SideBar';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { DownOutlined, UserOutlined, LogoutOutlined, LockOutlined, FormOutlined, NotificationOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { logout, setsite } from '../redux/actions'
import { authGetData } from '../service';
import { routes } from '../routes'
import ResetPwForm from "./forms/ResetPwForm";
import { createFromIconfontCN } from '@ant-design/icons';
import usePrevious from '../hooks/usePrevious';
const { Header, Content, Footer, Sider } = Layout;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2316353_c5btxnvl0br.js',
});
export function Base(props){
    const [collapsed, setCollapsed] = useState(false)
    const [resetVisible, setResetVisible] = useState(false)
    const [dot, setDot] = useState(false)
    const location = useLocation()
    const history = useHistory()
    const user = useSelector(state=>state.user)
    const site = useSelector(state=>state.site)
    const dispatch = useDispatch()
    const prev_site = usePrevious(site)

    function handleMenuClick(e) {
        if(e.key === '2'){
            authGetData('/api/logout', user.token)
                .then(data=>{
                    if(data.status === 'success'){
                        history.replace({pathname: '/login'})
                        dispatch(logout())
                    }
                })
                .catch(e=>{
                    history.replace({ pathname: '/login' })
                    dispatch(logout())
                })
        }else if(e.key === '1'){
            setResetVisible(true)
        }else if(e.key === '3'){
            history.push({ pathname: '/post' })
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" icon={<LockOutlined />}>
                重置密码
            </Menu.Item>
            {user.is_admin &&
                <Menu.Item key="3" icon={<FormOutlined />}>
                写通知
                </Menu.Item>
            }
            <Menu.Item key="2" icon={<LogoutOutlined />}>
                登出
            </Menu.Item>
        </Menu>
    );

    const handleCancel = () => {
        setResetVisible(false)
    }

    useEffect(()=>{
        authGetData('/api/inbox/dot', user.token)
            .then((data) => {
                if (data.status === 'success') {
                    setDot(data.dot)
                }
            })
    },[user.token])

    const handleNavClick = e => {
        dispatch(setsite(e.key))
        if (prev_site !== e.key){
            if(e.key === 'insurance'){
                history.push('/insurance/index')
            }else{
                history.push('/dashboard')
            }
        }
    }

    const findRoute = routes.find(el => el.path === location.pathname)

    return (location.pathname === '/login' || location.pathname === '/') ? null :
    (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                    breakpoint="xl" collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                <SideBar />
            </Sider>
                <Layout className="site-layout" style={{ marginLeft: collapsed?80:200 }}>
                <Header className="site-layout-background site-head" style={{ padding: 0,paddingRight: '20px', textAlign: 'right' }} >
                    <Menu onClick={handleNavClick} selectedKeys={[site.site]} mode="horizontal" style={{background:'transparent'}}>
                        <Menu.Item key="etc">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconFont style={{fontSize:24, lineHeight: 0, height: 24}} type="iconetc" />
                                <span>智运货车</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item key="insurance">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconFont style={{ fontSize: 24, lineHeight: 0, height: 24 }} type="iconbaoxian1" />
                                <span>智运保险</span>
                            </div>
                        </Menu.Item>
                    </Menu>
                    <Space>
                        <Button type="link" className="user-btn">
                            <Tooltip title="消息中心">
                                <Link to='/inbox'>
                                    <Badge dot={dot}>
                                        <NotificationOutlined style={{ color: '#fff' }} />
                                    </Badge>
                                </Link>
                            </Tooltip>
                        </Button>
                        <Dropdown overlay={menu}>
                            <Button type="link" className="user-btn">
                                <Space>
                                    <UserOutlined /> 
                                    {user.username} 
                                    {user.is_admin &&
                                        <Tag color="orange">管理员</Tag>
                                    }
                                    {user.is_agent ?
                                        <Tag color="volcano">渠道商</Tag>:
                                        user.is_salesman &&
                                            <Tag color="red">业务员</Tag>
                                    }
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Space>
                </Header>
                <Modal
                    visible={resetVisible}
                    title="重置密码"
                    onCancel={handleCancel}
                    footer={null}
                >
                    <ResetPwForm onFinished={handleCancel} />                            
                </Modal>
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item><Link to="/dashboard">控制面板</Link></Breadcrumb.Item>
                            {findRoute != null && findRoute.parent != null &&
                                <Breadcrumb.Item><Link to={findRoute.parent.path}>{findRoute.parent.name}</Link></Breadcrumb.Item>
                        }
                        {location.pathname !== '/dashboard' && findRoute && 
                            <Breadcrumb.Item>{findRoute.name}</Breadcrumb.Item>
                        }
                    </Breadcrumb>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>智运大数据 www.zy.zone ©2024 <Tag color="blue">客服电话:400-656-6756</Tag></Footer>
            </Layout>
        </Layout>
    );
}