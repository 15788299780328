import React, { useState } from 'react'
import { Menu } from 'antd';
import {
    CarOutlined,
    DashboardOutlined,
    FileOutlined,
    HistoryOutlined,
    MoneyCollectOutlined,
    BranchesOutlined,
    PaperClipOutlined,
    SearchOutlined,
    CodeOutlined,
    RedditOutlined,
    UsergroupAddOutlined,
    PayCircleOutlined,
    DribbbleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InsuranceSideBar from './insurance/InsuranceSideBar';
import useActiveBar from '../hooks/useActiveBar';

const { SubMenu } = Menu;

export default function SideBar(props) {
    let [seletedKey, setSeletedKey] = useState("1")
    const user = useSelector(state => state.user)
    const site = useSelector(state => state.site)
    
    useActiveBar(setSeletedKey)

    const [openKeys, setOpenKeys] = React.useState([]);
    const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8']
    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <>
            <div className="logo">
                <img
                    alt="logo"
                    src={process.env.PUBLIC_URL + "/logo_new.png"}
                />
            </div>
            {site.site === "etc" && (
                <Menu
                    theme="dark"
                    openKeys={openKeys}
                    selectedKeys={[seletedKey]}
                    mode="inline"
                    onOpenChange={onOpenChange}
                >
                    <Menu.Item key="1" icon={<DashboardOutlined />}>
                        <Link to="/dashboard">控制面板</Link>
                    </Menu.Item>
                    <SubMenu
                        key="sub2"
                        icon={<FileOutlined />}
                        title="税号管理"
                    >
                        <Menu.Item key="4">
                            <Link to="/taxno">已有税号</Link>
                        </Menu.Item>
                        {(user.is_admin || user.is_salesman) && (
                            <Menu.Item key="5">
                                <Link to="/add_taxno">新增税号</Link>
                            </Menu.Item>
                        )}
                    </SubMenu>
                    <SubMenu key="sub1" icon={<CarOutlined />} title="车辆备案">
                        <Menu.Item key="2">
                            <Link to="/vehicles">已备案车辆</Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <Link to="/add_vehicle">新增车辆</Link>
                        </Menu.Item>
                        {user.is_admin && (
                            <Menu.Item key="50">
                                <Link to="/add_vehicle_block">新增黑名单</Link>
                            </Menu.Item>
                        )}
                        {user.is_admin && (
                            <Menu.Item key="51">
                                <Link to="/vehicle_blocks">黑名单车辆</Link>
                            </Menu.Item>
                        )}
                    </SubMenu>
                    <Menu.Item key="99" icon={<BranchesOutlined />}>
                        <Link to="/yd_offline">运单管理</Link>
                    </Menu.Item>
                    {/* <SubMenu
                        key="sub3"
                        icon={<BranchesOutlined />}
                        title="运单管理"
                    >
                        <Menu.Item key="6"><Link to="/orders/all">所有运单</Link></Menu.Item>
                        <Menu.Item key="13"><Link to="/orders/invoiced">已开票运单</Link></Menu.Item>
                        <Menu.Item key="7"><Link to="/add_order">添加运单</Link></Menu.Item>
                    </SubMenu> */}
                    <Menu.Item key="100" icon={<PaperClipOutlined />}>
                        <Link to="/fp_offline">发票管理</Link>
                    </Menu.Item>
                    <SubMenu
                        key="sub5"
                        icon={<SearchOutlined />}
                        title="车辆信息查询"
                    >
                        <Menu.Item key="14">
                            <Link to="/tracking/location">车辆最新位置</Link>
                        </Menu.Item>
                        <Menu.Item key="15">
                            <Link to="/tracking/line">车辆轨迹</Link>
                        </Menu.Item>
                        <Menu.Item key="19">
                            <Link to="/tracking/innet">车辆入网验证</Link>
                        </Menu.Item>
                        <Menu.Item key="20">
                            <Link to="/tracking/exist">车辆确认</Link>
                        </Menu.Item>
                        <Menu.Item key="21">
                            <Link to="/tracking/vehicle_license">
                                车辆行驶证信息
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="22"><Link to="/tracking/trans_license">道路运输证查询</Link></Menu.Item> */}
                        <Menu.Item key="23">
                            <Link to="/tracking/owner">车主真实性验证</Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="32" icon={<PayCircleOutlined />}>
                        <Link to="/finance_index">申请开票</Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<HistoryOutlined />}>
                        <Link to="/records">交易记录</Link>
                    </Menu.Item>
                    {user.is_salesman && (
                        <Menu.Item key="9" icon={<MoneyCollectOutlined />}>
                            <Link to="/users">我的客户</Link>
                        </Menu.Item>
                    )}
                    {user.is_admin && (
                        <SubMenu
                            key="sub4"
                            icon={<MoneyCollectOutlined />}
                            title="充值&用户管理"
                        >
                            <Menu.Item key="9">
                                <Link to="/users">所有用户</Link>
                            </Menu.Item>
                            <Menu.Item key="11">
                                <Link to="/add_user">新增用户</Link>
                            </Menu.Item>
                            <Menu.Item key="10">
                                <Link to="/topup">给用户充值</Link>
                            </Menu.Item>
                        </SubMenu>
                    )}
                    {user.is_admin && (
                        <SubMenu
                            key="sub7"
                            icon={<UsergroupAddOutlined />}
                            title="业务员管理"
                        >
                            <Menu.Item key="25">
                                <Link to="/salesmans">所有业务员</Link>
                            </Menu.Item>
                            <Menu.Item key="24">
                                <Link to="/add_salesman">新增业务员</Link>
                            </Menu.Item>
                        </SubMenu>
                    )}
                    {user.is_admin && (
                        <SubMenu
                            key="sub8"
                            icon={<DribbbleOutlined />}
                            title="财务开票管理"
                        >
                            <Menu.Item key="33">
                                <Link to="/accountant_index">待处理任务</Link>
                            </Menu.Item>
                        </SubMenu>
                    )}
                    {user.is_admin && (
                        <Menu.Item key="28" icon={<RedditOutlined />}>
                            <Link to="/transfer_record">打款通知</Link>
                        </Menu.Item>
                    )}
                    {user.is_admin && (
                        <SubMenu
                            key="sub6"
                            icon={<CodeOutlined />}
                            title="接口扣费管理"
                        >
                            <Menu.Item key="16">
                                <Link to="/tracking_api/all">所有接口</Link>
                            </Menu.Item>
                            <Menu.Item key="17">
                                <Link to="/tracking_api/add">新增接口</Link>
                            </Menu.Item>
                        </SubMenu>
                    )}
                </Menu>
            )}
            {site.site === "insurance" && <InsuranceSideBar />}
        </>
    );
}