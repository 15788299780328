import React from "react";
import { Form, Input, Button, message } from 'antd';
import { authPostData } from '../../../service'
import { useSelector } from "react-redux";

export default function FapiaoForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const onFinish = values => {
        console.log(props.data)
        authPostData('/api/accountant/task/complete', user.token, {
            relate_apply_record: props.data,
            ...values
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg)
                props.onFinished()
            } else {
                message.error(data.msg)
            }
            form.resetFields()
        }).catch(p => {
            p.then(data => {
                message.error(data.message)
            })
        })
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="发票代码" name="fpdm" rules={[{ required: true, message: '发票代码' }]}>
                <Input
                    placeholder="请输入发票代码"
                />
            </Form.Item>
            <Form.Item label="发票号码" name="fphm" rules={[{ required: true, message: '发票号码' }]}>
                <Input
                    placeholder="请输入发票号码"
                />
            </Form.Item>
            <Form.Item label="快递单号" name="express_number" rules={[{ required: true, message: '快递单号' }]}>
                <Input
                    placeholder="请输入快递单号"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" block>
                    完成开票
                </Button>
            </Form.Item>
        </Form>
    )
}