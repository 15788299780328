import { LOGIN, LOGOUT, FETCHING } from '../actionTypes';

const initialState = {
    username: '未登录用户',
    token: '',
    isFetching: false,
    is_salesman: false
}

export default function login(state=initialState, action) {
    switch (action.type) {
        case LOGIN:
            const { username, token, is_admin, is_salesman, is_agent } = action.payload;
            return {
                ...state,
                username,
                token,
                is_admin,
                is_agent,
                is_salesman: is_salesman && !is_admin,
                isFetching: false
            }
        case LOGOUT:
            return {
                ...initialState
            }
        case FETCHING:
            return {
                ...state,
                isFetching: action.payload.isFetching
            }

        default:
            return state;
    }
}