import React, {  useEffect, useState } from 'react'
import { Table, message, Button, Steps, List, Divider } from 'antd'
import { authGetData, authPostData } from '../../service';
import { useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Step } = Steps;
export default function UserApplyInvoicePage(){
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [currentStep, setCurrentStep] = React.useState(0);
    const [data, setData] = useState([])
    const [addressData, setAddressData] = useState([])
    const [invoiceData, setInvoiceData] = useState(null)
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    const user = useSelector(state=>state.user)
    const history = useHistory()

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    const columns = [
        
        {
            title: '用户',
            dataIndex: 'username',
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '详情',
            dataIndex: 'detail',
        }
    ];

    const addressColumns = [
        {
            title: '收件人',
            dataIndex: 'recipient',
        },
        {
            title: '地址',
            dataIndex: 'address',
        },
        {
            title: '联系电话',
            dataIndex: 'contact_number',
        },
        {
            title: '邮政编码',
            dataIndex: 'zipcode',
        },
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    useEffect(()=>{
        authGetData(`/api/invoicing/record_list`, user.token)
            .then(data=>{
                setLoading(false)
                setData(data.items)
                setPagination({
                    ...pagination,
                    total: data.total
                })
            })
            .catch(p=>{
                if (user.token === '') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
        
        authGetData(`/api/invoicing/address`, user.token)
            .then(data => {
                if(data.status==='success'){
                    setAddressData([data.item])
                }else{
                    message.error(data.msg)
                }
                
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    console.log(data)
                    message.error(data.message)
                })
            })
        
        authGetData(`/api/invoicing/invoice_info`, user.token)
            .then(data => {
                console.log(data)
                if(data.status==='success'){
                    setInvoiceData(data.item)
                }else{
                    message.error(data.msg)
                }
                
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    console.log(data)
                    message.error(data.message)
                })
            })
    }, [user.token]);

    
    const onSelectChange = (selectedRowKeys, selectRows) => {
        console.log(selectedRowKeys)
        setSelectedRowKeys(selectedRowKeys);
        let totalAmount = selectRows.reduce(function (accumulator, current){
            return accumulator + current.amount
        }, 0)
        setTotalAmount(totalAmount)
    };
    
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const next = () => {
        if (selectedRowKeys.length === 0){
            message.info('请先选择需要开票的金额')
            return
        }
        if (currentStep === 0) {
            setCurrentStep(currentStep + 1);
        }

        if (currentStep === 1){
            authPostData(`/api/invoicing/apply`, user.token, {
                relate_money_record: selectedRowKeys
            })
            .then(data => {
                if(data.status==='success'){
                    message.success(data.msg)
                    history.push('/finance_index')
                    return
                }
                message.error(data.msg)
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
        }
        
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const steps = [
        {
            title: '选择发票',
            content: 
                <>
                    <div className="toptip">
                        <ExclamationCircleOutlined /> 已选择 <span>{selectedRowKeys.length}</span> 项 , 开票总金额 {totalAmount}
                    </div>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection
                        }}
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={data}
                        pagination={false}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </>
        },
        {
            title: '确认开票信息和地址',
            content:
                <>
                    {invoiceData!=null &&
                        <>
                            <h3>开票信息</h3>
                            <p>发票抬头：{invoiceData.invoice_title}</p>
                            <p>税务登记证号：{invoiceData.taxno}</p>
                            <p>开户银行名称：{invoiceData.bank_name}</p>
                            <p>基本开户账号：{invoiceData.bank_no}</p>
                            <p>注册场所地址：{invoiceData.bank_register_address}</p>
                            <p>注册固定电话：{invoiceData.contact}</p>
                        </>
                    }
                    <Divider></Divider>
                    <h3>地址</h3>
                    <Table
                        columns={addressColumns}
                        rowKey={record => record.id}
                        dataSource={addressData}
                        pagination={false}
                        loading={loading}
                    />
                </>,
        },
    ];

    return (
        <>
            <Steps current={currentStep} style={{maxWidth:600}}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div className="steps-content" style={{paddingTop:30}}>
                {steps[currentStep].content}
            </div>
            
            <div style={{textAlign:'right', marginTop:20}}>
                <div className="steps-action">
                    {currentStep === 1 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                            上一步
                        </Button>
                    )}
                    {currentStep < 2 && (
                        <Button type="primary" onClick={() => next()}>
                            {currentStep===0?'下一步':'申请开票'}
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}