import React, {  useEffect, useState } from 'react'
import { Table, message, Button } from 'antd'
import { authGetData } from '../service';
import { useSelector } from 'react-redux';


export default function TrackingApisPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const user = useSelector(state=>state.user)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '路径',
            dataIndex: 'api'
        },
        {
            title: '每次计费',
            dataIndex: 'time_price'
        },
        {
            title: '每日计费上限',
            dataIndex: 'day_price'
        },
        {
            title: '每月计费上限',
            dataIndex: 'month_price'
        },
        {
            title: '阶梯计费',
            dataIndex: 'step_price'
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Button size="small" type="primary">编辑</Button>
            ),
        },
    ];

    useEffect(()=>{
        setLoading(true)
        authGetData(`/api/tracking/apis`, user.token)
            .then(data=>{
                setLoading(false)
                setData(data.items)
            })
            .catch(p=>{
                setLoading(false)
                if (user.token === '') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
    }, [user.token]);

    return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            loading={loading}
        />
    )
}