import React, {  useEffect, useState } from 'react'
import { Table, Tag, message, Popconfirm, Button } from 'antd'
import { authGetData, authDeleteData } from '../service';
import { useSelector } from 'react-redux';


export default function VehicleBlocks(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const user = useSelector(state=>state.user)

    let handleTableChange = (pagination)=>{
        setPage(pagination.current)
    }

    let handlerDelete = (id)=>{
        authDeleteData(`/api/vehicle/block`, user.token,{id:id})
            .then(reps_data=>{
                if(reps_data.status==='success'){
                    message.success(reps_data.msg)
                    return
                }
                message.error(reps_data.msg)
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '车牌号',
            dataIndex: 'plate_number',
            width: '20%',
        },
        {
            title: '黑名单原因',
            dataIndex: 'reason',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Popconfirm title="Are you sure？" okText="确定" cancelText="取消" onConfirm={handlerDelete.bind(this, record.id)}>
                    <Button type="link" size="small" danger>删除</Button>
                </Popconfirm>
            ),
        },
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const pagination = {
        total: total,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    }

    useEffect(()=>{
        setLoading(true)
        authGetData(`/api/vehicles/block/page/${page}`, user.token)
            .then(data=>{
                setLoading(false)
                setData(data.items)
                setTotal(data.total)
            })
            .catch(p=>{
                if (user.token === '') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
    }, [page, user.token]);


    return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
        />
    )
}