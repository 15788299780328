import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, message } from 'antd';
import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service'

const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function VehicleForm(){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [taxnos, setTaxnos] = useState([])
    const onFinish = values => {
        console.log(values);
        authPostData('/api/vehicle', user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
            }else{
                message.error(data.msg);
            }
        })
        .catch(p => {
            p.then(data => {
                message.error(data.message)
            })

            if (!p) {
                message.error('服务器出错');
            }
        })
    };

    const filterTax = (inputValue, option) => {
        return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    }

    useEffect(() => {
        authGetData('/api/taxnos', user.token)
            .then(data => {
                setTaxnos(data.items)
                if(data.items.length===0) {
                    message.error('请先添加税号, 如已有税号, 请等待审核')
                    return
                }

                form.setFieldsValue({
                    taxno: data.items[0].id
                })
            })
    }, [user.token])

    return (
        <Form {...layout} initialValues={{plate_color:"1"}} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item name="plate_number" label="车牌号" rules={[{ required: true, message:"请填写车牌号"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="plate_color" label="车牌颜色" rules={[{ required: true, message: "请选择车牌颜色" }]}>
                <Select
                    placeholder="请选择车牌颜色"
                >
                    <Option value="1">黄色</Option>
                </Select>
            </Form.Item>
            <Form.Item name="taxno" label="企业税号" rules={[{ required: true, message: "请输入企业税号" }]}>
                <Select
                    showSearch
                    filterOption={filterTax}
                    placeholder="请选择企业税号"
                >
                    {taxnos.map(tax =>
                        <Select.Option key={tax.id} value={tax.id}>{tax.taxno}</Select.Option>
                    )}
                </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}