import React, {  useEffect, useState } from 'react'
import { Table, message } from 'antd'
import { authGetData } from '../service';
import { useSelector } from 'react-redux';

export default function TransferRecordsPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const user = useSelector(state=>state.user)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    
    let handleTableChange = (pagination) => {
        setPage(pagination.current)
    }

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const pagination = {
        total: total,
        showTotal: showTotal,
        pageSize: 20,
        showSizeChanger: false
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '用户ID',
            dataIndex: 'user_id',
        },
        {
            title: '用户名',
            dataIndex: 'username'
        },
        {
            title: '打款金额',
            dataIndex: 'fee'
        },
        {
            title: '打款日期',
            dataIndex: 'last_update'
        }
    ];

    useEffect(()=>{
        setLoading(true)
        authGetData(`/api/transfer_record/page/${page}`, user.token)
            .then(data=>{
                setLoading(false)
                setData(data.items)
                setTotal(data.total)
            })
            .catch(p=>{
                setLoading(false)
                if (user.token === '') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
    }, [page, user.token]);

    return (
        <>
            <Table
                columns={columns}
                pagination={pagination}
                rowKey={record => record.id}
                dataSource={data}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}