import React, { useEffect, useState } from 'react'
import { Table, message } from 'antd'
import { authGetData, authPostData } from '../service';
import { useSelector } from 'react-redux';
import RecordSearchForm from './forms/RecordSearchForm'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function RecordsPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const user = useSelector(state=>state.user)
    const [searchMode, setSearchMode] = useState(false)
    const [fieldsValue, setFieldsValue] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    let history = useHistory()

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '10%',
        },
        {
            title: '用户',
            dataIndex: 'username',
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '明细',
            dataIndex: 'detail',
        },
        {
            title: '时间',
            dataIndex: 'last_update',
        },
        // {
        //     title: '操作',
        //     key: 'action'
        // },
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const searchResult = (data, _fieldsValue) => {
        setData(data.items)
        setSearchMode(true)
        setFieldsValue(_fieldsValue)
        setPagination({
            ...pagination,
            current: 1,
            total: data.total
        })
    }

    const searchClear = () => {
        setSearchMode(false)
        history.go(0)
    }

    useEffect(()=>{
        setLoading(true)
        if (searchMode) {
            authPostData(`/api/records/search/page/${pagination.current}`, user.token, {
                ...fieldsValue
            })
                .then(data => {
                    if (data == null) return;
                    setData(data.items)
                    setPagination({
                        ...pagination,
                        total: data.total
                    })
                    setLoading(false)
                })
                .catch(p => {
                    if (user.token === '') return
                    p.then(data => {
                        message.error(data.message)
                    })
                })
        } else {
            authGetData(`/api/records/page/${pagination.current}`, user.token)
                .then(data=>{
                    if(data == null) return;
                    setLoading(false)
                    setData(data.items)
                    setPagination({
                        ...pagination,
                        total: data.total
                    })
                })
                .catch(p=>{
                    if(user.token==='') return
                    p.then(data=>{
                        message.error(data.message)
                    })
                })
        }
    }, [pagination.current, user.token]);


    return (
        <>
            <RecordSearchForm onFinish={searchResult} onClear={searchClear} />
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    )
}