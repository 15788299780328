import React from "react";
import { Form, Input, Button, message, Row, Col, Typography } from 'antd';

import { MobileOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux'
import { login } from '../redux/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { postData } from '../service'

const { Title } = Typography;

export default function LoginPage(){
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation()
    let { from } = location.state || { from: { pathname: "/dashboard" } };

    const onFinish = values => {
        if(!/\d{11}/.test(values.mobile)){
            alert('手机号格式错误, 请重试')
        }
        
        postData('/api/login', {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                dispatch(login(data));
                history.replace({ pathname: from.pathname })
            }else{
                message.error(data.msg)
            }
        }).catch(p=>{
            if (!p) {
                message.error('服务器出错');
                return
            }
            p.then(data=>{
                message.error(data.message)
            })
        })
    }


    return (
        <div style={{overflow:'hidden'}}>
            {/* <img style={{ width: 150,position:'fixed',left:30,top:30 }} alt="货通智云" src={process.env.PUBLIC_URL + '/wai_logo.png'} /> */}
            <Row style={{maxWidth:1000}} className="login-wrap">
                <Col span={10}>
                    <img style={{maxWidth:'100%'}} alt="货通智云" src={process.env.PUBLIC_URL + '/left1.png'} />
                </Col>
                <Col span={10} offset={4} style={{ boxShadow: 'rgb(72 177 251 / 30%) 0px 0px 10px'}}>
                    <div className="login-container">
                        <div className="login-form">
                            <Title level={3} className="login-title">欢迎登录</Title>
                            <Form
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item name="mobile" rules={[{ required: true, message: '请输入手机号' }]}>
                                    <Input autoComplete="off" prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
                                </Form.Item>
                                <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="请输入密码"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button" block>
                                        登录
                            </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
            <p style={{ textAlign: 'center' }}>Copyright©2023  常州甲保御网络科技有限公司 版权所有 <a target="_blank" rel="noopener noreferrer" href="http://beian.miit.gov.cn/">苏ICP备17025925号-7</a></p>
        </div>
    )
}