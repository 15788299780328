import React, { useEffect, useRef, useState } from 'react';
import '../gt';
import { getData } from '../service';

export default function GeeTestComponent(props) {
    const embedElem = useRef(null)
    const [wait, setWait] = useState(true)
    const [data, setData] = useState(null)

    var handlerEmbed = function (captchaObj) {
        captchaObj.appendTo(embedElem.current);
        captchaObj.onReady(function () {
            setWait(false)
        });
        captchaObj.onSuccess(function () {
            props.validate(captchaObj.getValidate())
        });
    };

    useEffect(() => {
        getData(`/api/pc-geetest/register?${(new Date()).getTime()}`)
            .then(data => {
                setData(data)
                window.initGeetest({
                    gt: data.gt,
                    challenge: data.challenge,
                    product: "embed", // 产品形式，包括：float，embed，popup。注意只对PC版验证码有效
                    offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
                    new_captcha: data.new_captcha
                }, handlerEmbed);
            })
    }, []);
    
    return (
        <div>
            <div id="embed-captcha" ref={embedElem}></div>
            {wait &&
                <p>正在加载验证码......</p>
            }
        </div>
    )
}