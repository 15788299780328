import React, {  useEffect, useState } from 'react'
import { Table, message, Button, Tag, Drawer, Descriptions } from 'antd'
import { authGetData, authPostData } from '../service';
import { useSelector } from 'react-redux';

export default function InboxPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [currentRow, setCurrentRow] = useState({})
    const [drawVisible, setDrawVisible] = useState(false);


    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    const user = useSelector(state=>state.user)

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    const setRead = record => {
        if(record.status === 1){
            return
        }
        authPostData(`/api/inbox/${record.id}/status`, user.token)
            .then(data => {
                if(data.status === 'success'){
                    record.status = 1
                    setCurrentRow(record)
                    return
                }
                setCurrentRow(record)
                message.error(data.msg)
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const viewContent = record => {
        setDrawVisible(true);
        if(record.status === 1){
            setCurrentRow(record)
        }else{
            setRead(record)
        }
    }

    const onClose = () => {
        setDrawVisible(false);
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            width: '50%',
            render: (title, record)=>{
                return <Button type={record.status===0?"link":'text'} onClick={viewContent.bind(this, record)}>{title}</Button>
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                let tagName;
                let color;
                if (status === 0) {
                    tagName = '未读'
                    color = 'processing'
                } else if (status === 1) {
                    tagName = '已读'
                    color = 'success'
                }

                return (
                    <Tag color={color} key={status}>
                        {tagName}
                    </Tag>
                )
            }
        },
        {
            title: '时间',
            dataIndex: 'last_update',
        }
    ];

    if(user.is_admin){
        columns.push({
            title: '用户',
            dataIndex: 'user_name',
        })
    }

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    useEffect(()=>{
        authGetData(`/api/inbox/page/${pagination.current}`, user.token)
            .then(data => {
                if (data == null) return;
                setLoading(false)
                setData(data.items)
                setPagination({
                    ...pagination,
                    total: data.total
                })
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [pagination.current, user.token]);

    return (
        <>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                onRow={record => {
                    return {
                        onDoubleClick: event => { viewContent(record) },
                    }
                }}
            />
            <Drawer
                title="通知详情"
                placement="right"
                onClose={onClose}
                visible={drawVisible}
                width="750"
            >
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="标题">{currentRow.title}</Descriptions.Item>
                    <Descriptions.Item label="内容">
                        <div dangerouslySetInnerHTML={{ __html: currentRow.content }}></div>
                    </Descriptions.Item>
                    <Descriptions.Item label="链接">
                        {currentRow.link!=null?
                            <a rel="noopener noreferrer" target="_blank" href={currentRow.link}>查看详情</a>:
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="消息日期">{currentRow.last_update}</Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    )
}