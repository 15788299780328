import { Button, Card, Col, Row, Space, message, Tabs } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { Link, useLocation,  } from "react-router-dom/cjs/react-router-dom.min";
import { getData, authGetData } from "../../service";
import { useSelector } from 'react-redux';
const { TabPane } = Tabs;

export default function InsurancePage() {
    const location = useLocation()
    const path = location.pathname.split('/')[2]
    const [data, setData] = useState([])
    const [visible, setVisible] = useState(false)
    const [policyContent, setPolicyContent] = useState('')
    const user = useSelector(state => state.user)
    const [customerId, setCustomerId] = React.useState(null);

    useEffect(()=>{
        authGetData(`/api/insurance/${path}`, user.token)
        .then(data=>{
            if(data.status==='success'){
                setData(data.data)
            }
        })
        .catch(p => {
            if (user.token === '') return
            p.then(data => {
                message.error(data.message)
            })
        })
    }, [user.token])

    useEffect(() => {
        authGetData('/api/app/insurance/identity', user.token)
            .then((data) => {
                if (data.status === 'success') {
                    setCustomerId(data.customer_id)
                    return
                }
                message.error(data.msg)
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [user.token])


    const gridStyle = {
        width: '50%',
    };

    const onClose = ()=>{
        setVisible(false)
    }

    const checkPolicy = (content) => {
        setPolicyContent(content)
        setVisible(true)
    }

    return (
        <>
        {path==='danpiao' && data.map((item, index)=>(
            <Card title={<Space><Avatar src={process.env.PUBLIC_URL + '/insurance_images/' + item.img} /><span>{item.company}</span></Space>} key={item}>
                {item.polices.map((policy, _index)=>(
                    <Card.Grid style={gridStyle} key={policy+_index}>
                        <h3>{policy.product_name}</h3>
                        <Row>
                            <Col span={12} >保险费率：{policy.product_rate}%</Col>
                            <Col span={12}>最小保额：{policy.min_coverage}万</Col>
                            <Col span={12}>自动核保：{policy.max_coverage}万</Col>
                            <Col span={12}>最低保费：<span style={{color: 'red'}}>{policy.min_premium}</span>元</Col>
                        </Row>
                        <Row justify='space-between' style={{marginTop:10}}>
                            <Col><Button type="link" size='small' style={{ paddingLeft: 0 }} onClick={checkPolicy.bind(this, policy.product_outlined)}>查看条款</Button></Col>
                            <Col>
                                <Button type="primary" size='small'>
                                    <Link to={`/insurance/orderFrame?product_code=${policy.product_code}&customer_id=${customerId}`}>立即投保</Link>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Grid>
                ))}
                    
            </Card>
        ))
        }
        {path==='guzhu' && data.map((item, index)=>(
            <Card title={<Space><Avatar src={process.env.PUBLIC_URL + '/insurance_images/' + item.img} /><span>{item.company}</span></Space>} key={item}>
                {item.polices.map((policy, _index)=>(
                    <Card.Grid style={gridStyle} key={policy+_index}>
                        <h3>{policy.insurance_name}</h3>
                        <Row>
                            <Col span={12} >意外事故/残疾：{policy.accident}万</Col>
                            <Col span={12}>医疗费用：{policy.medical_expense}万</Col>
                            <Col span={12}>最少投保人数：{policy.minimum}人</Col>
                            
                        </Row>
                        <Row style={{ padding: 16, background: '#F8F9FD', color: '#212529', borderRadius: 5, marginTop:16, marginLeft: -10, marginRight: -10}}>
                            <Col span={24}>住院补贴：{policy.hospitalization_benefits}</Col>
                            <Col span={24}>误工费：{policy.tardy_cost}</Col>
                            <Col span={24}>{policy.policy_total}</Col>
                        </Row>
                        <Row justify="end" style={{marginTop:10}}>
                            <Col>
                                <Button type="primary" size='small'>
                                    <a target="_blank" rel="noopener noreferrer" href={policy.more_link}>下载投保模版</a>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Grid>
                ))}
                    
            </Card>
        ))
        }
        {path==='nianbao' && 
                <Tabs defaultActiveKey="1" type="card" size='large' style={{ marginBottom: 32 }}>
                { data.map((item, index)=>(
                    <TabPane tab={item.name} key={'category' + item.category}>
                        <Tabs defaultActiveKey="1" tabPosition="left" style={{ marginBottom: 32, marginTop: 32 }}>
                            {item.facilitator.map((subitem, subindex)=>(
                                <TabPane tab={<Space><Avatar src={process.env.PUBLIC_URL + '/insurance_images/' + subitem.img} /><span>{subitem.company}</span></Space>}  key={'facilitator'+subindex}>
                                    <Card key={subitem}>
                                        {subitem.polices.map((policy, _index)=>(
                                            <Card.Grid style={{width: '50%'}} key={policy+_index}>
                                                <h3>{policy.insurance_name}</h3>
                                                { item.category !== 5 ?
                                                    <Row>
                                                        <Col span={12} >单次限额：{policy.every_value}万</Col>
                                                        <Col span={12}>累计限额：{policy.policy_total}万</Col>
                                                        {item.category === 3 ?
                                                            <Col span={12}>免赔：{policy.abatement}人</Col>:
                                                            <Col span={12}>年营业额：{policy.other_abatement}</Col>
                                                        }
                                                        <Col span={12}>保费(万)：{policy.annual_premium}</Col>
                                                    </Row>:
                                                    <Row>
                                                        <Col span={12}>赔偿限额：{policy.every_value}万</Col>
                                                        <Col span={12}>货物价值：{policy.judge}万</Col>
                                                    </Row>
                                                }
                                                <Row justify="end" style={{marginTop:10}}>
                                                    <Col>
                                                        <Button type="primary" size='small'>
                                                            <a target="_blank" rel="noopener noreferrer" href={policy.more_link}>下载投保模版</a>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Grid>
                                        ))}
                                    </Card>

                                </TabPane>
                            ))
                            }
                        </Tabs>
                    </TabPane>
                ))}
            </Tabs>
        }
        <Modal visible={visible} title="服务条款" onOk={onClose} onCancel={onClose} okText="我知道了" cancelText="关闭">
            <div dangerouslySetInnerHTML={{ __html: policyContent }}></div>
        </Modal>
        </>

    )
}