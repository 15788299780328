import React from "react";
import { Form, Button, Row, Col, Input, message, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const { RangePicker } = DatePicker;

export default function TaxnoSearchForm(props) {
    const [form] = Form.useForm();
    const onFinish = values => {
        message.loading('正在查询..', 1)
        const fieldsValue = {
            ...values,
            upload_time: values.upload_time != null ? values.upload_time.map(x => x.format('YYYY-MM-DD 00:00:00')) : null,
        }
        props.onFinish(fieldsValue)
    };

    return (
        <>
            <Form form={form} 
                onFinish={onFinish} 
                style={{
                maxWidth: '100%', background: '#fafafa',
                padding: '16px'}}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="company_name" label="公司名称" style={{ marginBottom: 0 }}>
                            <Input autoComplete="off" placeholder="请输入公司名称" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item style={{ marginBottom: 0 }} name="upload_time" label="上传时间" >
                            <RangePicker locale={locale} />
                        </Form.Item>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                                form.resetFields();
                                props.onClear()
                            }}
                        >
                            清空
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}