import React, { useState } from "react";
import { Form, message, InputNumber, Typography, Button, Row, Col, Input  } from 'antd';
import { useSelector } from "react-redux";
import { authGetData, authPostData } from '../../service';
import useInterval from "../../hooks/useInterval";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function UserGiftDaysForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [btnLoading, setBtnLoading] = useState(false)
    const [countDown, setCountDown] = useState(30)
    const [isRunning, setIsRunning] = useState(false)
    const url = `/api/user/gift_days`
    const onFinish = values => {
        setBtnLoading(true)

        authPostData(url, user.token, {
            ...values,
            user_id: props.data.id
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
                
                props.onFinished({
                    expire_date: data.expire_date,
                })
            }else{
                message.error(data.msg);
            }

            setBtnLoading(false)
            return data
        })
        .catch((p)=>{
            p.then(data=>{
                message.error(data.message);
            })

            if(!p){
                message.error('服务器出错');
            }

            setBtnLoading(false)
        }).finally(()=>{
            form.resetFields()
        })
    };

    useInterval(() => {
        if (countDown <= 1) {
            setCountDown(30)
            setIsRunning(false)
            return
        }
        setCountDown(countDown - 1)
    }, isRunning ? 1000 : null)

    const getCode = () => {
        setIsRunning(true)
        authGetData('/api/code', user.token)
            .then(data => {
                if (data.status === 'success') {
                    message.success(data.msg);
                } else {
                    message.error(data.msg);
                }
            })
            .catch((p) => {
                p.then(data => {
                    message.error(data.message);
                })

                if (!p) {
                    message.error('服务器出错');
                }
            })
    }

    return (
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item label="用户">
                <Typography.Text className="ant-form-text" type="secondary">
                    {props.data.username}
                </Typography.Text>
            </Form.Item>
            <Form.Item name="days" label="赠送天数" style={{ marginBottom: 16, marginTop: 16 }} rules={[{ required: true, message: "必填" }]}>
                <InputNumber min={1} max={366} />
            </Form.Item>
            <Form.Item label="验证码(3分钟有效)" >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="code"
                            noStyle
                            rules={[{ required: true, message: '请输入短信验证码' }]}
                        >
                            <Input autoComplete='off' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Button onClick={getCode} disabled={isRunning}>{isRunning ? `${countDown}秒后重新获取` : '获取验证码'}</Button>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={btnLoading}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}