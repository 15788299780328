import React, { useState,useEffect } from "react";
import { Form, Input, Button, message, DatePicker, Row, Col, notification, Upload, Select } from 'antd';
import { useSelector } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';
import { authPostData, authGetData } from '../../service';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
const { RangePicker } = DatePicker;
const { Option } = Select;
export default function InvoiceSearchForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const [btnLoading, setBtnLoading] = useState(false)
    const [taxnos, setTaxnos] = useState([])

    const url = '/api/invoices/search/page/1'
    const onFinish = values => {
        const fieldsValue = {
            ...values,
            kprq: values.kprq != null ? values.kprq.map(x=>x.format('YYYY-MM-DD 00:00:00')):null,
            statistics: values.statistics != null ? values.statistics.format('YYYY-MM') : null
        }

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...fieldsValue
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg);
                if(data.total_sum){
                    notification.success({
                        message: '查询结果统计',
                        description: `共${data.total}条, 价税合计: ${data.total_sum[0].toFixed(2)}, 税额: ${data.total_sum[1].toFixed(2)}`,
                        duration: 0
                    });
                }
                props.onFinish(data, fieldsValue)
            } else {
                message.error(data.msg);
            }
            setBtnLoading(false)
        })
            .catch((p) => {
                p.then(data => {
                    message.error(data.message);
                })

                if (!p) {
                    message.error('服务器出错');
                }

                setBtnLoading(false)
            })
    };

    const get_excel_name = (info) => {
        if (info.file.status === 'done') {
            if(info.file.response.status==='success'){
                window.open(info.file.response.path)
                if (info.file.response.exclude_path){
                    window.open(info.file.response.exclude_path)
                }
            }
        }
    };

    useEffect(() => {
        authGetData(user.is_admin ? '/api/all_taxnos': '/api/taxnos', user.token)
            .then(data => {
                setTaxnos(data.items)
            })
    }, [user.token])

    const initialValues = {
        has_tax: "2"
    }
    
    return (
        <Form form={form} onFinish={onFinish} initialValues={initialValues} style={{
            maxWidth: '100%', background: '#fafafa',
            padding: '16px'}}>
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item name="num" label="运单编号" style={{ marginBottom: 0 }}>
                        <Input placeholder="请输入运单编号(50个字符以内)" allowClear />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name="has_tax" label="是否征税" style={{ marginBottom: 0}}>
                        <Select>
                            <Option value="0">不征税</Option>
                            <Option value="1">征税</Option>
                            <Option value="2">全部</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="fphm" label="发票号码" style={{ marginBottom: 0 }}>
                        <Input placeholder="请输入发票号码" allowClear />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="kprq" label="开票日期" style={{ marginBottom: 0 }}>
                        <RangePicker locale={locale} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                {(user.username === '货通天下' || user.username === '新沂市道达物流有限公司' || user.username === '江苏安邦物流股份有限公司' || user.username === '常州货通天下供应链管理有限公司泰州分公司') &&
                    <Col span={8}>
                        <Form.Item
                            name="upload"
                            label="票据归集"
                            style={{ marginTop: 20,marginBottom: 0 }}
                        >
                            <Upload
                                name="file"
                                action="/api/upload_search_execl"
                                accept='.xls'
                                headers={{
                                    'Authorization': `Bearer ${user.token}`
                                }}
                                onChange={get_excel_name}
                            >
                                <Button icon={<UploadOutlined />}>上传发票EXECL</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                }
                <Col span={4}>
                    <Form.Item name="cph" label="车牌号" style={{ marginTop: 16,marginBottom: 0 }}>
                        <Input placeholder="请输入车牌号" allowClear />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="title" label="公司抬头" style={{ marginBottom: 0, marginTop: 16 }}>
                        <Select 
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="选择一个抬头"
                            >
                            {taxnos.map(u =>
                                <Select.Option key={u.id} value={u.company_name}>{u.company_name}</Select.Option>
                                )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6} style={{ marginBottom: 0, marginTop: 16, textAlign:'right' }}>
                    <Button type="primary" htmlType="submit" loading={btnLoading}>搜索</Button>
                    <Button
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                            form.resetFields();
                            props.onClear()
                        }}
                    >
                        清空
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}