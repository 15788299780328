import React from "react";
import { Form, Input, Button, message } from "antd";
import { MobileOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { authPostData } from '../../service';
import { useSelector } from "react-redux";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function ReviewForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const onFinish = values => {
        console.log(values)
        authPostData(`/api/add_user`, user.token, {
            ...values
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg)
                if (typeof props.onFinished == 'function') {
                    props.onFinished(data)
                }
            } else {
                message.error(data.msg)
            }
        }).catch(p => {
            if (!p) {
                message.error('服务器出错');
                return
            }

            p.then(data => {
                message.error(data.message)
            })
        })
    }

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item name="username" rules={[{ required: true, message: '用户名' }]}>
                <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名" />
            </Form.Item>
            <Form.Item name="mobile" rules={[{ required: true, message: '请输入手机号' }]}>
                <Input autoComplete="off" prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="请输入密码"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" block>
                    新增用户
                </Button>
            </Form.Item>
        </Form>
    )
}