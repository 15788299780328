import { SETSITE } from '../actionTypes'

const initialState = {
    site: 'etc'  
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SETSITE:
            const { site } = action.payload;
            return {
                ...state,
                site
            }

        default:
            return state;
    }
}