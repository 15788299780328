import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { authGetData, authPostData } from '../../../service';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export default function UserInvoiceForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const history = useHistory()
    const onFinish = values => {
        authPostData(`/api/invoicing/invoice_info`, user.token, {
            ...values
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg)
                history.push('/finance_index')
            } else {
                message.error(data.msg)
            }
        }).catch(p => {
            if (!p) {
                message.error('服务器出错');
                return
            }

            p.then(data => {
                message.error(data.message)
            })
        })
    }

    useEffect(() => {
        authGetData(`/api/invoicing/invoice_info`, user.token)
            .then(data => {
                
                if (data.status === 'failed') {
                    authGetData('/api/taxnos/page/1', user.token)
                        .then(data => {
                            if(data.totle === 0) return;
                            const item = data.items[0]
                            form.setFieldsValue({
                                invoice_title: item.company_name,
                                taxno: item.taxno,
                                bank_name: item.bank_name,
                                bank_no: item.bank_number,
                                contact: item.contact_phone_number,
                                bank_register_address: item.address
                            })
                        })
                    return
                }
                if (data.status === 'success') {
                    form.setFieldsValue({
                        ...data.item
                    })
                }

            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [user.token])

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onFinish}
        >

            <Form.Item name="invoice_title" label="发票抬头" rules={[{ required: true, message: '发票抬头' }]}>
                <Input autoComplete="off" placeholder="发票抬头" />
            </Form.Item>
            <Form.Item name="taxno" label="税务登记证号" rules={[{ required: true, message: '税务登记证号' }]}>
                <Input autoComplete="off" placeholder="税务登记证号" />
            </Form.Item>
            <Form.Item name="bank_name" label="开户银行名称" rules={[{ required: true, message: '开户银行名称' }]}>
                <Input autoComplete="off" placeholder="开户银行名称" />
            </Form.Item>
            <Form.Item name="bank_no" label="开户账号" rules={[{ required: true, message: '开户账号' }]}>
                <Input autoComplete="off" placeholder="开户账号" />
            </Form.Item>
            <Form.Item name="bank_register_address" label="注册场所地址" rules={[{ required: true, message: '注册场所地址' }]}>
                <Input autoComplete="off" placeholder="注册场所地址" />
            </Form.Item>
            <Form.Item name="contact" label="注册固定电话" rules={[{ required: true, message: '注册固定电话' }]}>
                <Input autoComplete="off" placeholder="注册固定电话" />
            </Form.Item>
            
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}