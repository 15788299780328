import React, { useEffect, useState } from 'react'
import { Table, Tag, message, Popconfirm, Space, Modal, Button, Image, Drawer, Descriptions } from 'antd'
import { authGetData, authDeleteData, authPostData } from '../service';
import { useSelector } from 'react-redux';
import TaxnoForm  from './forms/TaxnoForm'
import ReviewForm from './forms/ReviewForm'
import TaxnoSearchForm from './forms/TaxnoSearchForm';

export default function TaxnoPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [visible, setVisible] = useState(false)
    const [visible1, setVisible1] = useState(false)
    const [currentRow, setCurrentRow] = useState({})
    const [fieldsValue, setFieldsValue] = useState({ status: 0 })
    const [drawVisible, setDrawVisible] = useState(false);
    const [currentView, setCurrentView] = useState({});

    const user = useSelector(state=>state.user)

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    
    let handleTableChange = (_pagination) => {
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    let handlerDelete = (id, index)=>{
        authDeleteData(`/api/taxno/${id}`, user.token)
            .then(reps_data=>{
                let _data = data.slice()
                _data.splice(index, 1)
                message.success(reps_data.msg)
                setData(_data)
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.message)
                })

                if (!p) {
                    message.error('服务器出错');
                }
            })
    }

    const handlerEdit = (id, index)=>{
        setVisible(true)
        setCurrentRow(data[index])
    }
    
    const handlerReview = (id, index)=>{
        setVisible1(true)
        setCurrentRow(data[index])
    }

    const submitRoadNet = (id, index)=>{
        authGetData(`/api/agent/taxno/${id}`, user.token)
            .then(data=>{
                if(data.status==='success'){
                    message.success(data.msg)
                }else{
                    message.error(data.msg)
                }
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.message)
                })

                if (!p) {
                    message.error('服务器出错');
                }
            })
    }

    const viewDetail = (record, index)=>{
        showDrawer()
        setCurrentView(record)
    }



    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '80px',
            fixed: 'left',
        },
        {
            title: '用户名',
            dataIndex: 'username',
        },
        {
            title: '企业名称',
            dataIndex: 'company_name',
        },
        {
            title: '税号',
            dataIndex: 'taxno',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status, record) => {
                if (status === 0) {
                    return (
                        <Tag key={status}>未审核</Tag>
                    )
                } else if (status === 1){
                    return (
                        <Tag color='cyan' key={status}>审核通过</Tag>
                    )
                }else {
                    return (
                        <>
                            <Tag color='red' key={status}>审核未通过</Tag>
                            <p style={{marginBottom: 0}}>{record.status_msg}</p>
                        </>
                    )
                }
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Space>
                    {record.status !== 1 &&
                        <Button size="small" type="primary" onClick={handlerEdit.bind(this, record.id, index)}>编辑</Button>
                    }
                    {user.is_admin &&
                        <>
                            <Button size="small" type="link" onClick={handlerReview.bind(this, record.id, index)}>审核</Button>
                            {record.is_agent_taxno &&
                                <Button size="small" type="link" onClick={submitRoadNet.bind(this, record.id, index)}>提交路网</Button>
                            }
                        </>
                    }
                    <Button size="small" type="link" onClick={viewDetail.bind(this, record)}>详情</Button>
                    {user.is_admin &&
                        <Popconfirm title="Are you sure？" okText="确定" cancelText="取消" onConfirm={handlerDelete.bind(this, record.id, index)}>
                            <Button size="small" type="link" danger>删除</Button>
                        </Popconfirm>
                    }
                </Space>
            ),
        },
    ];

    const onFinished = (taxno) =>{
        let row = currentRow
        // row.taxno = taxno.taxno
        row.status = 0
        // row.company_name = taxno.company_name
        // row.address = taxno.address
        // row.phone_number = taxno.phone_number
        // row.bank_name = taxno.bank_name
        // row.bank_number = taxno.bank_number
        // row.contact_name = taxno.contact_name
        // row.contact_phone_number = taxno.contact_phone_number
        row.username = '用户' + taxno.user
        setCurrentRow(row)
        setVisible(false);
    }

    const onFinished1 = (taxno) =>{
        let row = currentRow
        console.log(taxno)
        console.log(taxno.user)
        row.status = taxno.taxno_status
        row.status_msg = taxno.status_msg
        setCurrentRow(row)
        setVisible1(false);
    }

    useEffect(() => {
        authPostData(`/api/taxnos/page/${pagination.current}`, user.token, {
            ...fieldsValue
        })
        .then(data => {
            if (data == null) return;
            setLoading(false)
            setData(data.items)
            setPagination({
                ...pagination,
                total: data.total
            })
        })
        .catch(p => {
            if (user.token === '') return
            p.then(data => {
                message.error(data.message)
            })
        })
    }, [pagination.current, user.token, fieldsValue]);

    const searchFinished = (fieldsValue) => {
        setFieldsValue(fieldsValue)
        setPagination({
            ...pagination,
            current: 1
        })
    }

    const onClear = () => {
        setFieldsValue({})
    }

    const showDrawer = () => {
        setDrawVisible(true);
    };
    const onClose = () => {
        setDrawVisible(false);
    };

    return (
        <>
            <TaxnoSearchForm onFinish={searchFinished} onClear={onClear}></TaxnoSearchForm>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                onRow={record => {
                    return {
                        onDoubleClick: event => { viewDetail(record) },
                    }
                }}
                scroll={{
                    x: 1600
                }}
            />
            {user.is_admin &&
                <Modal
                    title="编辑税号"
                    visible={visible}
                    footer={null}
                    onCancel={()=>setVisible(false)}
                >
                    <TaxnoForm data={currentRow} onFinished={onFinished} />
                </Modal>
            }
            {user.is_admin &&
                <Modal
                    title="审核"
                    visible={visible1}
                    footer={null}
                    onCancel={()=>setVisible1(false)}
                >
                    <ReviewForm data={currentRow} onFinished={onFinished1} />
                </Modal>
            }
            <Drawer
                title="税号详情"
                placement="right"
                onClose={onClose}
                visible={drawVisible}
                width="550"
            >
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="企业名称">{currentView.company_name}</Descriptions.Item>
                    <Descriptions.Item label="税号">{currentView.taxno}</Descriptions.Item>
                    <Descriptions.Item label="地址">{currentView.address}</Descriptions.Item>
                    <Descriptions.Item label="电话">{currentView.phone_number}</Descriptions.Item>
                    <Descriptions.Item label="开户行">{currentView.bank_name}</Descriptions.Item>
                    <Descriptions.Item label="银行账号">{currentView.bank_number}</Descriptions.Item>
                    <Descriptions.Item label="联系人">{currentView.contact_name}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">{currentView.contact_phone_number}</Descriptions.Item>
                    <Descriptions.Item label="营业执照">
                        {currentView.business_license!=null ?
                            <Image
                                width={80}
                                src={currentView.business_license}
                            />:null
                    }
                    </Descriptions.Item>
                    <Descriptions.Item label="开户许可证">
                        {currentView.account_license != null ?
                            <Image
                                width={80}
                                src={currentView.account_license}
                            /> : null
                        }
                    </Descriptions.Item>
                    {user.is_admin &&
                        <>
                        <Descriptions.Item label="上传人">{currentView.uploader}</Descriptions.Item>
                        <Descriptions.Item label="上传时间">{currentView.last_update}</Descriptions.Item>
                        </>
                    }
                </Descriptions>
            </Drawer>
        </>
    )
}