import React, { useState } from 'react'
import { Menu } from 'antd';
import {
    DashboardOutlined,
    ContainerOutlined,
    GlobalOutlined,
    ExperimentOutlined,
    InsuranceOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useActiveBar from '../../hooks/useActiveBar';


export default function InsuranceSideBar(props) {
    let [seletedKey, setSeletedKey] = useState("1")

    useActiveBar(setSeletedKey)

    return (
        <Menu theme="dark" mode="inline" selectedKeys={[seletedKey]} >
            <Menu.Item key="26" icon={<DashboardOutlined />}>
                <Link to="/insurance/index">保险首页</Link>
            </Menu.Item>
            <Menu.Item key="40" icon={<ContainerOutlined />}>
                <Link to="/insurance/danpiao">单票货运险</Link>
            </Menu.Item>
            <Menu.Item key="41" icon={<ExperimentOutlined />}>
                <Link to="/insurance/nianbao">年保责任险</Link>
            </Menu.Item>
            <Menu.Item key="42" icon={<GlobalOutlined />}>
                <Link to="/insurance/guzhu">雇主责任险</Link>
            </Menu.Item>
            <Menu.Item key="43" icon={<InsuranceOutlined />}>
                <Link to="/insurance/orders">我的保单</Link>
            </Menu.Item>
        </Menu>

    )
}