import React from "react";
import LoginPage from "./components/Login"
import DashBoardPage from "./components/DashBoard"
import VehiclePage from "./components/Vehicle"
import VehicleForm from "./components/forms/VehicleForm";
import TaxnoPage from "./components/Taxno";
import TaxnoForm from "./components/forms/TaxnoForm";
import OrderForm from "./components/forms/OrderForm";
import OrdersPage from "./components/Orders";
import TopupForm from "./components/forms/TopupForm";
import UsersPage from "./components/Users";
import RecordsPage from "./components/Records";
import InvoicesPage from "./components/Invoices";
import TrackingApisPage from "./components/TrackingApis";
import Tracking from "./components/Tracking";
import TrackingQuery from "./components/TrackingQuery";
import RegisterForm from "./components/forms/RegisterForm";
import ApiPriceForm from "./components/forms/ApiPriceForm";
import SalesmanForm from "./components/forms/SalesmanForm";
import Salesmans from "./components/Salesmans";
import {Post} from "./components/Post";
import InsuranceIndexPage from "./components/insurance/InsuranceIndex";
import InsurancePage from "./components/insurance/Insurance";
import InsuranceOrdersPage from "./components/insurance/InsuranceOrders";
import SelfTopupForm from "./components/forms/SelfTopup";
import TransferRecordsPage from "./components/TransferRecords";
import UserInvoiceForm from "./components/forms/finance/UserInvoiceForm";
import UserAddressForm from "./components/forms/finance/UserAddressForm";
import FinanceIndexPage from "./components/finance/FinanceIndex";
import UserApplyInvoicePage from "./components/finance/UserApplyInvoice";
import AccountantIndexPage from "./components/finance/AccountantIndex";
import InboxPage from "./components/Inbox";
import ZoidFramePage from "./components/insurance/ZoidFrame";
import LandingPage from "./components/Landing";
import VehicleBlockForm from "./components/forms/VehicleBlockForm";
import VehicleBlocks from "./components/VehicleBlocks";
import Offline from "./components/Offline";

export const routes = [
    {
        path: "/",
        name: "首页",
        sidebar: () => <LandingPage />,
        isPrivate: false,
        exact: true,
    },
    {
        path: "/login",
        name: "登录",
        sidebar: () => <LoginPage />,
        isPrivate: false,
    },
    {
        path: "/dashboard",
        name: "控制面板",
        sidebar: () => <DashBoardPage />,
        isPrivate: true,
        key: "1",
    },
    {
        path: "/vehicles",
        name: "车辆列表",
        sidebar: () => <VehiclePage />,
        isPrivate: true,
        key: "2",
    },
    {
        path: "/add_vehicle",
        name: "新增车辆",
        sidebar: () => <VehicleForm />,
        isPrivate: true,
        key: "3",
    },
    {
        path: "/add_vehicle_block",
        name: "新增车辆黑名单",
        sidebar: () => <VehicleBlockForm></VehicleBlockForm>,
        isPrivate: true,
        key: "50",
    },
    {
        path: "/vehicle_blocks",
        name: "黑名单车辆",
        sidebar: () => <VehicleBlocks></VehicleBlocks>,
        isPrivate: true,
        key: "51",
    },
    {
        path: "/taxno",
        name: "税号管理",
        sidebar: () => <TaxnoPage />,
        isPrivate: true,
        key: "4",
    },
    {
        path: "/add_taxno",
        name: "新增税号",
        sidebar: () => <TaxnoForm />,
        isPrivate: true,
        key: "5",
    },
    // {
    //     path: "/orders/all",
    //     name: "所有运单",
    //     sidebar: () => <OrdersPage />,
    //     isPrivate: true,
    //     key: "6",
    // },
    // {
    //     path: "/orders/invoiced",
    //     name: "已开票运单",
    //     sidebar: () => <OrdersPage />,
    //     isPrivate: true,
    //     key: "13",
    // },
    // {
    //     path: "/invoices",
    //     name: "发票管理",
    //     sidebar: () => <InvoicesPage />,
    //     isPrivate: true,
    //     key: "12",
    // },
    {
        path: "/yd_offline",
        name: "下线通知",
        sidebar: () => <Offline />,
        isPrivate: true,
        key: "99",
    },
    {
        path: "/fp_offline",
        name: "下线通知",
        sidebar: () => <Offline />,
        isPrivate: true,
        key: "100",
    },
    // {
    //     path: "/add_order",
    //     name: "添加运单",
    //     sidebar: () => <OrderForm />,
    //     isPrivate: true,
    //     key: "7",
    // },
    {
        path: "/tracking/location",
        name: "车辆实时位置",
        sidebar: () => <Tracking />,
        isPrivate: true,
        key: "14",
    },
    {
        path: "/tracking/line",
        name: "车辆轨迹",
        sidebar: () => <Tracking />,
        isPrivate: true,
        key: "15",
    },
    {
        path: "/tracking/innet",
        name: "车辆入网验证",
        sidebar: () => <TrackingQuery />,
        isPrivate: true,
        key: "19",
    },
    {
        path: "/tracking/exist",
        name: "车辆确认",
        sidebar: () => <TrackingQuery />,
        isPrivate: true,
        key: "20",
    },
    {
        path: "/tracking/vehicle_license",
        name: "车辆行驶证信息",
        sidebar: () => <TrackingQuery />,
        isPrivate: true,
        key: "21",
    },
    // {
    //     path: '/tracking/trans_license',
    //     name: '道路运输证信息',
    //     sidebar: () => <TrackingQuery />,
    //     isPrivate: true,
    //     key: '22'
    // },
    {
        path: "/tracking/owner",
        name: "车主真实性验证",
        sidebar: () => <TrackingQuery />,
        isPrivate: true,
        key: "23",
    },
    {
        path: "/records",
        name: "消费记录",
        sidebar: () => <RecordsPage />,
        isPrivate: true,
        key: "8",
    },
    {
        path: "/users",
        name: "所有用户",
        sidebar: () => <UsersPage />,
        isPrivate: true,
        key: "9",
    },
    {
        path: "/topup",
        name: "用户充值",
        sidebar: () => <TopupForm />,
        isPrivate: true,
        key: "10",
    },
    {
        path: "/add_user",
        name: "新增用户",
        sidebar: () => <RegisterForm />,
        isPrivate: true,
        key: "11",
    },
    {
        path: "/tracking_api/add",
        name: "新增接口",
        sidebar: () => <ApiPriceForm />,
        isPrivate: true,
        key: "17",
    },
    {
        path: "/tracking_api/all",
        name: "所有接口",
        sidebar: () => <TrackingApisPage />,
        isPrivate: true,
        key: "16",
    },
    {
        path: "/post",
        name: "写通知",
        sidebar: () => <Post />,
        isPrivate: true,
        key: "18",
    },
    {
        path: "/add_salesman",
        name: "新增业务员",
        sidebar: () => <SalesmanForm />,
        isPrivate: true,
        key: "24",
    },
    {
        path: "/salesmans",
        name: "所有业务员",
        sidebar: () => <Salesmans />,
        isPrivate: true,
        key: "25",
    },
    {
        path: "/insurance/index",
        name: "智运保险",
        sidebar: () => <InsuranceIndexPage />,
        isPrivate: true,
        key: "26",
    },
    {
        path: "/user/topup",
        name: "自助充值",
        sidebar: () => <SelfTopupForm />,
        isPrivate: true,
        key: "27",
    },
    {
        path: "/transfer_record",
        name: "公对公打款通知",
        sidebar: () => <TransferRecordsPage />,
        isPrivate: true,
        key: "28",
    },
    {
        path: "/apply_for_invoice",
        name: "申请开票",
        sidebar: () => <UserApplyInvoicePage></UserApplyInvoicePage>,
        isPrivate: true,
        key: "29",
        parent: {
            path: "/finance_index",
            name: "开票信息管理",
        },
    },
    {
        path: "/invoice_info",
        name: "开票信息",
        sidebar: () => <UserInvoiceForm></UserInvoiceForm>,
        isPrivate: true,
        key: "30",
        parent: {
            path: "/finance_index",
            name: "开票信息管理",
        },
    },
    {
        path: "/manage_address",
        name: "邮寄地址",
        sidebar: () => <UserAddressForm></UserAddressForm>,
        isPrivate: true,
        key: "31",
        parent: {
            path: "/finance_index",
            name: "开票信息管理",
        },
    },
    {
        path: "/finance_index",
        name: "开票信息管理",
        sidebar: () => <FinanceIndexPage></FinanceIndexPage>,
        isPrivate: true,
        key: "32",
    },
    {
        path: "/accountant_index",
        name: "财务人员开票首页",
        sidebar: () => <AccountantIndexPage />,
        isPrivate: true,
        key: "33",
    },
    {
        path: "/inbox",
        name: "消息中心",
        sidebar: () => <InboxPage />,
        isPrivate: true,
        key: "34",
    },
    {
        path: "/insurance/orderFrame",
        name: "正在投保",
        sidebar: () => <ZoidFramePage />,
        isPrivate: true,
        key: "35",
        parent: {
            path: "/insurance/danpiao",
            name: "单票货运险",
        },
    },
    {
        path: "/insurance/danpiao",
        name: "单票货运险",
        sidebar: () => <InsurancePage />,
        isPrivate: true,
        key: "40",
        parent: {
            path: "/insurance/index",
            name: "保险首页",
        },
    },
    {
        path: "/insurance/nianbao",
        name: "年保责任险",
        sidebar: () => <InsurancePage />,
        isPrivate: true,
        key: "41",
        parent: {
            path: "/insurance/index",
            name: "保险首页",
        },
    },
    {
        path: "/insurance/guzhu",
        name: "雇主责任险",
        sidebar: () => <InsurancePage />,
        isPrivate: true,
        key: "42",
        parent: {
            path: "/insurance/index",
            name: "保险首页",
        },
    },
    {
        path: "/insurance/orders",
        name: "我的保单",
        sidebar: () => <InsuranceOrdersPage></InsuranceOrdersPage>,
        isPrivate: true,
        key: "43",
        parent: {
            path: "/insurance/index",
            name: "保险首页",
        },
    },
];