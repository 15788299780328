import { LOGIN, LOGOUT, FETCHING, SETSITE} from './actionTypes'

export const login = (user) => {
    return {
        type: LOGIN,
        payload: {
            ...user
        }
    }
}

export const fetching = (user) => {
    return {
        type: FETCHING,
        payload: {
            ...user
        }
    }
}

export const logout = (user) => {
    return {
        type: LOGOUT
    }
}

export const setsite = (site) => {
    return {
        type: SETSITE,
        payload: {
            site
        }
    }
}

