import React from "react";
import { Form, Input, Button, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { authPostData } from '../../service'
import { useSelector } from "react-redux";

export default function ResetPwForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const onFinish = values => {
        authPostData('/api/resetpw', user.token, {
            ...values
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg)
                props.onFinished()
            } else {
                message.error(data.msg)
            }
            form.resetFields()
        }).catch(p => {
            p.then(data => {
                message.error(data.msg)
            })
        })
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Form.Item name="password" rules={[{ required: true, message: '请输入原密码' }]}>
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="请输入原密码"
                />
            </Form.Item>
            <Form.Item name="new_password" rules={[{ required: true, message: '请输入新密码' }]}>
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="请输入新密码"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" block>
                    重置密码
                </Button>
            </Form.Item>
        </Form>
    )
}