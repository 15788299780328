import { Divider, Typography } from "antd";
import React from "react";
const { Title, Paragraph, Text, Link } = Typography;

export default function Offline() {
    return (
        <div style={{ maxWidth: 600, margin: 'auto', padding: 16 }}>
            <Paragraph strong>
                智运ETC产品服务已下线，您可以继续使用智运大数据的车辆信息查询、货运车辆最新实时定位查询、货运车辆历史轨迹查询、货运车辆入网验证查询、货运车辆行驶证信息查询、货运车辆车主真实性验证查询等功能。
            </Paragraph>
            <Divider />
            <Paragraph strong>
                如您需要办理智运大数据余额退款，请致电
                <Text mark>156-5123-8080</Text>。
            </Paragraph>
            <Paragraph strong>
                感谢您长期以来对智运大数据平台的支持，我们将继续为您提供智慧物流相关大数据服务。
            </Paragraph>
        </div>
    );
}
