import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Select, DatePicker, Row, Col, Alert } from 'antd';
import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
const { RangePicker } = DatePicker;
export default function OrderSearchForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const [users, setUsers] = useState([])
    const [plateNumbers, setPlateNumbers] = useState([])
    const [taxnos, setTaxnos] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const url = '/api/orders/search/page/1'
    const onFinish = values => {
        console.log(values.start_time)
        const fieldsValue = {
            ...values,
            start_time: values.start_time!=null?values.start_time.map(x=>x.format('YYYY-MM-DD 00:00:00')):null,
            end_time: values.end_time!=null?values.end_time.map(x=>x.format('YYYY-MM-DD 00:00:00')):null,
            last_update: values.last_update != null ? values.last_update.map(x => x.format('YYYY-MM-DD 00:00:00')) : null
        }

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...fieldsValue
        })
        .then(data => {
            if (data.status === 'success') {
                message.success(data.msg);
                props.onFinish(data, fieldsValue)
            } else {
                message.error(data.msg);
            }
            setBtnLoading(false)
        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }

            setBtnLoading(false)
        })
    };

    const onValuesChange = (changedValues)=>{
        if(changedValues.user != null){
            form.resetFields(['title'])
            authGetData(`/api/user/${changedValues.user}/taxnos`, user.token)
                .then(data => {
                    setTaxnos(data.items)
                })
        }
    }

    const debounce = (fn, time) => {
        let timeout;

        return function () {
            const functionCall = () => fn.apply(this, arguments);

            clearTimeout(timeout);
            timeout = setTimeout(functionCall, time);
        }
    }

    const handlePlateSearch = value => {
        if (value) {
            authGetData(`/api/vehicles/tag/${value}`, user.token)
                .then(data => {
                    setPlateNumbers(data.items)
                })
        } else {
            setPlateNumbers([])
        }
    };

    const handlePlateChange = value => {
        // console.log('change', value)
    };

    useEffect(() => {
        if(!user.is_admin && !user.is_salesman) return;
        authGetData('/api/users', user.token)
            .then(data => {
                setUsers(data.items)
            })
    }, [user.token])

    return (
        <>
            <Form form={form} 
                onFinish={onFinish} 
                onValuesChange={onValuesChange}
                style={{
                maxWidth: '100%', background: '#fafafa',
                padding: '16px'}}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item name="num" label="运单编号" >
                            <Input placeholder="请输入运单编号(50个字符以内)" allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="plate_number" label="车牌号" >
                            <Select
                                showSearch
                                filterOption={false}
                                onSearch={debounce(handlePlateSearch, 500)}
                                onChange={handlePlateChange}
                                placeholder="请输入车牌号"
                                allowClear
                            >
                                {plateNumbers.map(plate =>
                                    <Select.Option key={plate.id} value={plate.id}>{plate.plate_number}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="status" label="运单状态">
                            <Select placeholder="请选择运单状态" allowClear>
                                <Select.Option value="0">未结束</Select.Option>
                                <Select.Option value="1">已结束</Select.Option>
                                <Select.Option value="2">已开票</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="order_type" label="运单类型" >
                            <Select placeholder="请选择运单类型" allowClear>
                                <Select.Option value="0">实时运单</Select.Option>
                                <Select.Option value="1">历史运单</Select.Option>
                                <Select.Option value="2">暂存运单</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={9}>
                        <Form.Item style={{marginBottom:0}} name="start_time" label="运单开始时间" >
                            <RangePicker locale={locale} />
                        </Form.Item>
                    </Col>

                    <Col span={9}>
                        <Form.Item style={{ marginBottom: 0 }} name="end_time" label="运单结束时间" >
                            <RangePicker locale={locale} />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" loading={btnLoading}>搜索</Button>
                        <Button
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                                form.resetFields();
                                props.onClear()
                            }}
                        >
                            清空
                        </Button>
                        
                    </Col>
                </Row>
                {(user.is_admin || user.is_salesman) &&
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="last_update" label="运单添加时间" style={{ marginBottom: 0, marginTop: 16 }}>
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="user" label="用户"  style={{ marginBottom: 0, marginTop: 16}}>
                                <Select 
                                    allowClear 
                                    placeholder="选择一个用户"
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {users.map(u =>
                                        <Select.Option key={u.username} value={u.id}>{u.username}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="title" label="抬头"  style={{ marginBottom: 0, marginTop: 16}}>
                            <Select allowClear placeholder="选择一个抬头">
                                    {taxnos.map(t =>
                                        <Select.Option key={t.taxno} value={t.id}>{t.company_name}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
            <Alert
                style={{margin:"10px 0"}}
                message="[实时运单]：运单结束开票1次、结束10日开票1次、20日开票1次，共开具3次。  [历史运单]：仅开票1次，20个自然日后可以查询发票开具情况"
                type="warning"
                closable
            />
        </>
    )
}