import React, { useState, useEffect } from "react";
import { Form, Button, message, Select, DatePicker, Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
const { RangePicker } = DatePicker;
export default function AccountantSearchForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const [users, setUsers] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const onFinish = values => {
        const fieldsValue = {
            ...values,
            start_time: values.start_time != null ? values.start_time.map(x=>x.format('YYYY-MM-DD')):null,
        }
        setBtnLoading(true)

        authPostData('/api/accountant/tasks/page/1', user.token, {
            ...fieldsValue
        })
        .then(data => {
            console.log(data)
            if (data.status === 'success') {
                message.success(data.msg);
                props.onFinished(data, fieldsValue)
            } else {
                message.error(data.msg);
            }
            setBtnLoading(false)
        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }

            setBtnLoading(false)
        })
    };

    useEffect(() => {
        authGetData('/api/accountant/users', user.token)
            .then(data => {
                setUsers(data.items)
            })
    }, [user.token])

    return (
        <>
            <Form form={form} 
                onFinish={onFinish} 
                style={{
                maxWidth: '100%', background: '#fafafa',
                padding: '16px'}}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item name="start_time" label="申请日期" style={{ marginBottom: 0 }}>
                            <RangePicker locale={locale} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="user_id" label="选择公司" style={{ marginBottom: 0 }}>
                            <Select allowClear placeholder="选择一个公司" optionFilterProp="children" showSearch>
                                {users.map(u =>
                                    <Select.Option key={u.username} value={u.id}>{u.username+`(未开票${u.count})`}</Select.Option>
                                    )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="status" label="开票状态" style={{ marginBottom: 0 }}>
                            <Select allowClear placeholder="选择开票状态">
                                <Select.Option value={0}>未开票</Select.Option>
                                <Select.Option value={1}>已开票</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" loading={btnLoading}>搜索</Button>
                        <Button
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                                form.resetFields();
                                props.onClear()
                            }}
                        >
                            清空
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}