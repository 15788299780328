import React, { useEffect, useState } from "react";
import { Form, Switch, Input, Button, message, Typography } from "antd";
import { authPostData } from '../../service';
import { useSelector } from "react-redux";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function ReviewForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const [statusMsgValidate, setStatusMsgValidate] = useState(['',''])
    const onFinish = values => {
        console.log(values)
        if (!values.status && (values.status_msg == null || values.status_msg.trim() === '')){
            setStatusMsgValidate(['error','请填写未通过原因'])
            return
        }else{
            setStatusMsgValidate(['',''])
        }
        
        authPostData(`/api/taxno_review/${props.data.id}`, user.token, {
            status: values.status ? 1 : 2,
            status_msg: values.status_msg
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg)
                if(typeof props.onFinished == 'function'){
                    props.onFinished(data)
                }
            } else {
                message.error(data.msg)
            }
        }).catch(p => {
            p.then(data => {
                message.error(data.msg)
            })
        })
    }

    useEffect(() => {
        if (props.data != null) {
            form.setFieldsValue({
                status: props.data.status!==1?false:true,
                status_msg: props.data.status_msg
            })
        }
    }, [props.data, form])

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="税号">
                <Typography.Text className="ant-form-text" type="primary">
                    {props.data.taxno}
                </Typography.Text>
            </Form.Item>
            <Form.Item name="status" label="是否通过" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Form.Item name="status_msg" label="未通过原因" validateStatus={statusMsgValidate[0]}
                help={statusMsgValidate[1]}>
                <Input />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    ) 
}