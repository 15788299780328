import React, {  useEffect, useState, useRef } from 'react'
import { Table, message, Button, Divider, Row, Col, Statistic, Modal, Tag, Popconfirm } from 'antd'
import { authGetData, authPostData } from '../../service';
import { useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FapiaoForm from '../forms/finance/FapiaoForm';
import AccountantSearchForm from '../forms/AccountantSearchForm';

export default function AccountantIndexPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [arrayIds, setArrayIds] = useState([])
    const [statistics, setStatistics] = useState([])
    const [selectedRows, setSelectedRows] = useState({})
    const [currentRow, setCurrentRow] = useState({})
    const [fieldsValue, setFieldsValue] = useState({status: 0})
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    const user = useSelector(state=>state.user)

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    const undo = (record) => {
        authGetData(`/api/invoicing/undo/${record.id}`, user.token)
        .then(data => {
            if (data.status === 'success') {
                record.status = 2
                setCurrentRow(record)
                message.success(data.msg)
                return
            }
            message.error(data.msg)
        })
        .catch(p => {
            if (user.token === '') return
            p.then(data => {
                message.error(data.message)
            })
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: '90px'
        },
        {
            title: '用户',
            dataIndex: 'title',
        },
        {
            title: '开票金额',
            dataIndex: 'fee',
        },
        {
            title: '邮寄地址',
            dataIndex: 'address',
            width: '15%'
        },
        {
            title: '开票信息',
            dataIndex: 'invoice_info',
            width: '20%'
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                let tagName;
                let color;
                if (status === 0) {
                    tagName = '待开票'
                    color = 'processing'
                } else if (status === 1) {
                    tagName = '已开票'
                    color = 'success'
                } else if (status === 2) {
                    tagName = '已撤销'
                    color = 'red'
                }

                return (
                    <Tag color={color} key={status}>
                        {tagName}
                    </Tag>
                )
            }
        },
        {
            title: '详情',
            dataIndex: 'detail'
        },
        {
            title: '申请时间',
            dataIndex: 'last_update',
        },
        {
            title: '操作',
            render: (text, record, index) => {
                return (
                    <Popconfirm
                        title="确定撤销吗?"
                        onConfirm={undo.bind(this, record)}
                        okText="Yes"
                        cancelText="No"
                        >
                        {record.status === 0 &&
                            <Button ghost type="primary">撤销</Button>
                        }
                    </Popconfirm>
                )
            }
        },
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    useEffect(()=>{
        authPostData(`/api/accountant/tasks/page/${pagination.current}`, user.token, {
            ...fieldsValue
        })
        .then(data => {
            if (data == null) return;
            setLoading(false)
            setData(data.items)
            setPagination({
                ...pagination,
                total: data.total
            })
        })
        .catch(p => {
            if (user.token === '') return
            p.then(data => {
                message.error(data.message)
            })
        })
    }, [pagination.current, user.token, fieldsValue]);
    
    useEffect(() => {
        authGetData(`/api/accountant/statistics`, user.token)
            .then(data => {
                if (data == null) return;
                if(data.status === 'success'){
                    setStatistics(data.results)
                }
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [user.token]);
    
    const onSelectChange = (_selectedRowKeys, _selectRows) => {
        let __selectRows = Object.assign({},selectedRows)
        __selectRows[pagination.current] = {
            selectedRowKeys: _selectedRowKeys,
            selectedRows: _selectRows
        }
        setSelectedRows(__selectRows);
        let totalAmount = 0
        let totalCount = 0
        for(let page in __selectRows){
            totalAmount += __selectRows[page].selectedRows.reduce(function (accumulator, current) {
                return accumulator + current.fee
            }, 0)
            totalCount += __selectRows[page].selectedRows.length
        }
        setTotalAmount(totalAmount)
        setTotalCount(totalCount)
    };

    const rowSelection = {
        selectedRowKeys: selectedRows[pagination.current]==null?[]:selectedRows[pagination.current].selectedRowKeys,
        onChange: onSelectChange,
    };

    const taskComplete = () => {
        let arr = []
        for (let page in selectedRows) {
            arr = arr.concat(selectedRows[page].selectedRowKeys)
        }

        if(arr.length === 0){
            message.info('至少勾选一个')
            return
        }
        
        setArrayIds(arr)
        setVisibleModal(true)
    };

    const onFinished = (obj) => {
        setVisibleModal(false)
        setSelectedRows({})

        let new_data = data.map(item=>{
            let index = arrayIds.indexOf(item.id)
            if(index!==-1){
                item.status = 1
            }
            return item
        })        
        setData(new_data)
    }

    const searchFinished = (data, fieldsValue) => {
        console.log(data, fieldsValue)
        setFieldsValue(fieldsValue)
        setData(data.items)
        setPagination({
            ...pagination,
            current: 1,
            total: data.total
        })
    }

    const onClear = () => {
        setFieldsValue({})
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={6}>
                    <Statistic title="需开发票总金额" value={statistics[0]/100} precision={2}/>
                    
                </Col>
                <Col span={8}>
                    <Statistic className="address-statistic" title="申请公司" value={statistics[1]} />
                    
                </Col>
                <Col span={8}>
                    <Statistic className="address-statistic" title="未处理任务" value={statistics[2]} />
                </Col>
            </Row>
            <Divider></Divider>
            <h3>待处理任务</h3>
            <AccountantSearchForm onFinished={searchFinished} onClear={onClear}></AccountantSearchForm>
            <div className="toptip">
                <ExclamationCircleOutlined /> 已选择 <span>{totalCount}</span> 项 , 开票总金额 {totalAmount}
            </div>
            <Table
                rowSelection= {{
                    type: 'checkbox',
                    ...rowSelection
                }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                locale={{emptyText:'暂无开票申请记录'}}
            />
            <div style={{ textAlign: 'left', marginTop: 20 }}>
                <div className="steps-action" style={{textAlign:'right'}}>
                    <Button type="primary" size="large" onClick={taskComplete}>去开票</Button>
                </div>
            </div>
            <Modal
                title="填写发票相关信息"
                visible={visibleModal}
                footer={null}
                onCancel={() => setVisibleModal(false)}
            >
                <FapiaoForm data={arrayIds} onFinished={onFinished} />
            </Modal>
        </>
    )
}