import React, {useEffect} from "react";
import { Form, Input, Button, message } from "antd";
import { authGetData, authPostData } from '../../../service';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export default function UserAddressForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const history = useHistory()
    
    const onFinish = values => {
        console.log(values)
        authPostData(`/api/invoicing/address`, user.token, {
            ...values
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg)
                history.push('/finance_index')

            } else {
                message.error(data.msg)
            }
        }).catch(p => {
            if (!p) {
                message.error('服务器出错');
                return
            }

            p.then(data => {
                message.error(data.message)
            })
        })
    }

    useEffect(() => {
        authGetData(`/api/invoicing/address`, user.token)
            .then(data => {
                if (data == null) return;
                if (data.status === 'success') {
                    form.setFieldsValue({
                        ...data.item
                    })
                }

            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [user.token])

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item name="recipient" label="收件人" rules={[{ required: true, message: '收件人' }]}>
                <Input autoComplete="off" placeholder="收件人" />
            </Form.Item>
            <Form.Item name="address" label="收件地址" rules={[{ required: true, message: '收件地址' }]}>
                <Input autoComplete="off" placeholder="收件地址" />
            </Form.Item>
            <Form.Item name="contact_number" label="联系电话" rules={[{ required: true, message: '联系电话' }]}>
                <Input autoComplete="off" placeholder="联系电话" />
            </Form.Item>
            <Form.Item name="zipcode" label="邮政编码" rules={[{ required: true, message: '邮政编码' }]}>
                <Input autoComplete="off" placeholder="邮政编码" />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}