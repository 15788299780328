import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as zoid from 'zoid/dist/zoid.frameworks.js';

const MyLoginZoidComponent = zoid.create({
    // The html tag used to render my component
    tag: 'insurance_order',
    // The url that will be loaded in the iframe or popup, when someone includes my component on their page
    url: `https://huotongbao.zhongyuanib.com/portal/test/index`,
    dimensions: {
        width: '900px',
        height: '700px'
    },
    // autoResize: {
    //     height: true,
    //     width: true,
    // },
});

let LoginZoidReactComponent = MyLoginZoidComponent.driver('react', {
    React: React,
    ReactDOM: ReactDOM
});
export default function ZoidFramePage() {
    
    const location = useLocation()
    const search_key = location.search.split(/[?&=]/)
    const history = useHistory()
    const onFinished = () => {
        history.push('/insurance/index')
    }

    useEffect(()=>{
        if (search_key[2] == null){
            history.push('/login')
        }
    }, [search_key])

    return (
        <div>
            <LoginZoidReactComponent customer={search_key[4]} code={search_key[2]} onFinished={onFinished} />
        </div>
    );
    
}