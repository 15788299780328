import {
    Button,
    Row,
    Col,
    Space,
    Typography,
    Card,
    Tabs,
    Divider,
    Statistic,
    Modal,
    message,
} from "antd";
import Layout, { Header, Content, Footer } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { createFromIconfontCN } from "@ant-design/icons";
import PotentialForm from "./forms/PotentialForm";

const { Title } = Typography;
const { TabPane } = Tabs;
const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_2394178_utglxesibi.js",
});
export default function LandingPage() {
    const [activeTab, setActiveTab] = useState("1");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const tabChange = (activeKey) => {
        setActiveTab(activeKey);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinished = (data) => {
        if (data.status === "success") {
            setIsModalVisible(false);
        } else {
            message.info("抱歉, 网络错误, 请添加右侧微信进行申请");
        }
    };

    return (
        <Layout>
            <Header>
                <Row justify="space-between">
                    <Col>
                        <img
                            style={{ width: 150 }}
                            alt="logo"
                            src={process.env.PUBLIC_URL + "/logo_new.png"}
                        />
                    </Col>
                    <Col>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsModalVisible(true);
                                }}
                            >
                                申请试用
                            </Button>
                            <Button type="primary" ghost>
                                <Link to="/login">立即登录</Link>
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Header>
            <Content className="landing-contianer">
                <div
                    style={{ background: "#fff", textAlign: "center" }}
                    className="container-relative"
                >
                    <video loop autoPlay="on" muted style={{ width: "100%" }}>
                        <source
                            src="https://img.56httx.com/mp4/data.mp4"
                            type="video/mp4"
                        />
                    </video>
                    <div className="container-wrap">
                        <Row className="abs">
                            <Col span={12} style={{ textAlign: "left" }}>
                                <h1 style={{ fontSize: 30, color: "#fff" }}>
                                    链接无限、体验无间
                                </h1>
                                <p>
                                    平台包含智能寻车定位和高速路网通行数据管理,
                                    助力物流企业减负增效
                                </p>
                                <div style={{ marginTop: 30 }}>
                                    <Button ghost type="default" size="large">
                                        <Link to="/login">立即登录</Link>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container-wrap">
                    <div className="landing-title">
                        <Title level={3}>8大核心产品智领行业</Title>
                        <p>丰富的智运产品体系，帮助每一个物流企业释放潜能</p>
                    </div>
                    <Card
                        className="landing-card"
                        title={
                            <Space>
                                <IconFont
                                    style={{ fontSize: 18 }}
                                    type="iconchanpin"
                                />
                                智运产品
                            </Space>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                    >
                        <Row justify="space-between" className="shadow-border">
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconpiaojuguanli"
                                    />
                                    <Title level={4}>高速通行管理</Title>
                                    <p>
                                        高速路网通行数据管理,
                                        助力物流企业减负增效。
                                    </p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconwangluohuoyun"
                                    />
                                    <Title level={4}>数字货运</Title>
                                    <p>
                                        创新数字货运大数据平台模式，基于大数据生态体系，依托强大的功能体系群，以领先的技术算法实现平台价值。
                                    </p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconwuliujiesuan"
                                    />
                                    <Title level={4}>物流结算</Title>
                                    <p>
                                        电子签约，实名认证，在线支付，账目清晰，整合物流产业链上下游资源，构建智慧物流生态。
                                    </p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconwuliubaoxian"
                                    />
                                    <Title level={4}>物流保险</Title>
                                    <p>
                                        对接货同宝保险云平台，打造专属物流保险产品方案，保障物流运输安全。
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        className="landing-card"
                        title={
                            <Space>
                                <IconFont
                                    style={{ fontSize: 18 }}
                                    type="iconzhiyunfuwu"
                                />
                                智运服务
                            </Space>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                    >
                        <Row justify="space-between" className="shadow-border">
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconjinrong"
                                    />
                                    <Title level={4}>金融</Title>
                                    <p>
                                        基于物流场景，通过大数据征信和智能风控面向物流企业和司机提供灵活、多样的金融服务。
                                    </p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconcaiwu"
                                    />
                                    <Title level={4}>财务</Title>
                                    <p>
                                        资深财务团队解决企业各发展阶段的服务需求，定期在线培训。
                                    </p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconfawu"
                                    />
                                    <Title level={4}>法务</Title>
                                    <p>
                                        专业法律团队，针对物流行业特点为每个物流企业量身设计专业化的法律服务方案。
                                    </p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <IconFont
                                        style={{ fontSize: 60 }}
                                        type="iconshebei"
                                    />
                                    <Title level={4}>设备</Title>
                                    <p>
                                        为物流仓储等企业提供叉车、
                                        起重设备、等物流装备采购和租赁方案，精准匹配业务需求，优化采购方案。
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        className="landing-card"
                        title={
                            <div>
                                <Title level={4}>智运大数据</Title>
                                <p
                                    style={{
                                        width: 408,
                                        whiteSpace: "normal",
                                    }}
                                >
                                    <small style={{ color: "#80859C" }}>
                                        解决跨企业、跨区域、跨行业物流数据互联互通难题，促进规模化和集约化经营，降低物流行业成本。
                                    </small>
                                </p>
                            </div>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                    >
                        <Row
                            justify="space-between"
                            className="shadow-border-2"
                            style={{ background: "#F7F8FF" }}
                        >
                            <Col span={12}>
                                <Row style={{ padding: 20 }}>
                                    <Col span={12}>
                                        <Card>
                                            <Title level={2}>
                                                1602{" "}
                                                <sup>
                                                    <small
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        家
                                                    </small>
                                                </sup>
                                            </Title>
                                            <p>累计服务企业</p>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card>
                                            <Title level={2}>
                                                9133{" "}
                                                <sup>
                                                    <small
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        万
                                                    </small>
                                                </sup>
                                            </Title>
                                            <p>累计降低企业成本</p>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card>
                                            <Title level={2}>
                                                10260{" "}
                                                <sup>
                                                    <small
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        万
                                                    </small>
                                                </sup>
                                            </Title>
                                            <p>累计客户保险金额</p>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card>
                                            <Title level={2}>
                                                5868{" "}
                                                <sup>
                                                    <small
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        万
                                                    </small>
                                                </sup>
                                            </Title>
                                            <p>累计提供企业贷款</p>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <img
                                    style={{
                                        width: 450,
                                        margin: "-100px auto 0",
                                        display: "block",
                                    }}
                                    alt="logo"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/right_banner.png"
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        className="landing-card"
                        title={
                            <div>
                                <Title level={4}>全方位平台优势</Title>
                                <p
                                    style={{
                                        width: 408,
                                        whiteSpace: "normal",
                                    }}
                                >
                                    <small style={{ color: "#80859C" }}>
                                        认知、习得、创见、预想、实现
                                    </small>
                                </p>
                            </div>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                    >
                        <Row
                            justify="space-between"
                            className="shadow-border-2"
                        >
                            <Col span={8}>
                                <Card>
                                    <Row>
                                        <Col span={6}>
                                            <IconFont
                                                style={{ fontSize: 60 }}
                                                type="iconfunengwuliuweilai"
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Title level={5}>
                                                赋能物流未来
                                            </Title>
                                            <p>
                                                开放商业思维，链接企业价值，共建服务生态，共创行业未来
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                    <Row>
                                        <Col span={6}>
                                            <IconFont
                                                style={{ fontSize: 60 }}
                                                type="iconyunyingfengxiankongzhi"
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Title level={5}>
                                                运营风险控制
                                            </Title>
                                            <p>
                                                通过数据校验和算力分析代替人工核查核算，降低企业经营风险。
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                    <Row>
                                        <Col span={6}>
                                            <IconFont
                                                style={{ fontSize: 60 }}
                                                type="iconchuangxinjiangbenzengxiao"
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Title level={5}>
                                                创新降本增效
                                            </Title>
                                            <p>
                                                最大程度减少企业人工处理成本，提高业务流转效率。
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                    <Row>
                                        <Col span={6}>
                                            <IconFont
                                                style={{ fontSize: 60 }}
                                                type="iconjinxiangshujuhongli"
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Title level={5}>
                                                尽享数据红利
                                            </Title>
                                            <p>
                                                抓住数据核心形态，全链路无间协同，以数据推服务，以服务助产业。
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                    <Row>
                                        <Col span={6}>
                                            <IconFont
                                                style={{ fontSize: 60 }}
                                                type="iconzhuanyehuadingzhi"
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Title level={5}>专业化定制</Title>
                                            <p>
                                                基于平台大数据信息分析，为物流企业提供智能产品及报价方案。
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                    <Row>
                                        <Col span={6}>
                                            <IconFont
                                                style={{ fontSize: 60 }}
                                                type="iconhexinchanpinzhenghe"
                                            />
                                        </Col>
                                        <Col span={18}>
                                            <Title level={5}>
                                                核心产品整合
                                            </Title>
                                            <p>
                                                产品整合输出，为客户提供综合性数字化创新服务。
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        className="landing-card"
                        title={
                            <div>
                                <Title level={4}>他们正在使用智运大数据</Title>
                                <p
                                    style={{
                                        width: 408,
                                        whiteSpace: "normal",
                                    }}
                                >
                                    <small style={{ color: "#80859C" }}>
                                        专业的产品，优质的服务，收获了物流业众多标杆客户
                                    </small>
                                </p>
                            </div>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                    >
                        <Tabs
                            defaultActiveKey={activeTab}
                            onChange={tabChange}
                            className="landing-tabs"
                        >
                            <TabPane
                                tab={
                                    <span>
                                        {activeTab == 1 ? (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/ysd_01.png"
                                                }
                                            />
                                        ) : (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/ysd_02.png"
                                                }
                                            />
                                        )}
                                    </span>
                                }
                                key="1"
                            >
                                <div className="tab-bg">
                                    <IconFont
                                        type="iconshangyinhao"
                                        style={{ fontSize: 36 }}
                                    />
                                    <h3>江苏运斯达物流有限公司</h3>
                                    <p>
                                        智运大数据为我们定制开发的货运车辆大数据管理平台，基于数字货运运单，实现方便快捷的车辆调度管理，帮助我们企业减负增效。
                                    </p>
                                    <div className="t-r">
                                        <IconFont
                                            type="iconxiayinhao"
                                            style={{ fontSize: 36 }}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {activeTab == 2 ? (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/zg_01.png"
                                                }
                                            />
                                        ) : (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/zg_02.png"
                                                }
                                            />
                                        )}
                                    </span>
                                }
                                key="2"
                            >
                                <div className="tab-bg">
                                    <IconFont
                                        type="iconshangyinhao"
                                        style={{ fontSize: 36 }}
                                    />
                                    <h3>臻广物流</h3>
                                    <p>
                                        智运大数据为我们定制数字货运管理平台，让我们实现了动态管理车辆数据，智能匹配运输状态，大大提高了自身运力，降低了车辆空驶率。
                                    </p>
                                    <div className="t-r">
                                        <IconFont
                                            type="iconxiayinhao"
                                            style={{ fontSize: 36 }}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {activeTab == 3 ? (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/ga_01.png"
                                                }
                                            />
                                        ) : (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/ga_02.png"
                                                }
                                            />
                                        )}
                                    </span>
                                }
                                key="3"
                            >
                                <div className="tab-bg">
                                    <IconFont
                                        type="iconshangyinhao"
                                        style={{ fontSize: 36 }}
                                    />
                                    <h3>广安物流</h3>
                                    <p>
                                        智运大数据为我们定制开发的货运车辆大数据管理平台，基于数字货运运单，实现方便快捷的车辆调度管理，帮助我们企业减负增效
                                    </p>
                                    <div className="t-r">
                                        <IconFont
                                            type="iconxiayinhao"
                                            style={{ fontSize: 36 }}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {activeTab == 4 ? (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/chb_01.png"
                                                }
                                            />
                                        ) : (
                                            <img
                                                style={{ width: 80 }}
                                                alt="logo"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/chb_02.png"
                                                }
                                            />
                                        )}
                                    </span>
                                }
                                key="4"
                            >
                                <div className="tab-bg">
                                    <IconFont
                                        type="iconshangyinhao"
                                        style={{ fontSize: 36 }}
                                    />
                                    <h3>查货宝物流</h3>
                                    <p>
                                        智运大数据为我们定制数字货运大数据管理平台，让我们实现了动态管理车辆数据，智能匹配运输状态，大大提高了自身运力，降低了车辆空驶率。
                                    </p>
                                    <div className="t-r">
                                        <IconFont
                                            type="iconxiayinhao"
                                            style={{ fontSize: 36 }}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </Card>
                </div>
                <div
                    style={{
                        backgroundColor: "#F7F8FF",
                        textAlign: "center",
                        padding: "60px 0",
                        marginTop: 80,
                    }}
                >
                    <h1 style={{ marginBottom: 30 }}>
                        颠覆传统管理模式，开启高效数字时代
                    </h1>
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            setIsModalVisible(true);
                        }}
                    >
                        申请试用
                    </Button>
                </div>
            </Content>
            <Footer
                style={{ background: "#181E28", color: "#666", paddingTop: 40 }}
            >
                <Row style={{ maxWidth: 800, margin: "auto" }}>
                    <Col span={8}>
                        <img
                            style={{ width: 150, marginTop: 20 }}
                            alt="logo"
                            src={process.env.PUBLIC_URL + "/logo_new.png"}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title={
                                <span style={{ color: "#999" }}>联系电话</span>
                            }
                            value={"400 - 656 - 6756"}
                            valueStyle={{ color: "#fff" }}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title={
                                <span style={{ color: "#999" }}>邮箱地址</span>
                            }
                            value={"cs@zy.zone"}
                            valueStyle={{ color: "#fff" }}
                        />
                    </Col>
                </Row>
                <Divider style={{ borderColor: "#333" }}></Divider>
                <p className="p-a" style={{ textAlign: "center" }}>
                    Copyright©2023 常州甲保御网络科技有限公司.
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://beian.miit.gov.cn/"
                    >
                        苏ICP备17025925号-7
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32040402000421"
                    >
                        <img
                            alt="网安备"
                            src={process.env.PUBLIC_URL + "/wang_an.png"}
                        />
                        苏公网安备：32040402000421
                    </a>
                </p>
                <p
                    className="p-a"
                    style={{ textAlign: "center", marginBottom: 0 }}
                >
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://beian.miit.gov.cn/"
                    >
                        增值电信业务经营许可证：苏B2-20190748
                    </a>{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.czjby.com/images/zzdx.jpg"
                    >
                        工商营业执照
                    </a>
                </p>
                <Modal
                    visible={isModalVisible}
                    onOk={handleOk}
                    destroyOnClose={true}
                    onCancel={handleCancel}
                    footer={null}
                    title={null}
                    width={710}
                >
                    <Row>
                        <Col span="12" style={{ padding: 30 }}>
                            <PotentialForm onFinished={onFinished} />
                        </Col>
                        <Col span="12">
                            <img
                                style={{ width: 320 }}
                                alt="二维码"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/potential_customer.png"
                                }
                            />
                        </Col>
                    </Row>
                </Modal>
            </Footer>
        </Layout>
    );
}
