import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Statistic, Collapse, Card, Divider, Button, Space, message} from 'antd'
import { authGetData, authPostData } from "../service";
import { Typography } from 'antd';
import { Link } from "react-router-dom";
import { createFromIconfontCN } from '@ant-design/icons';

const { Title } = Typography;
const { Panel } = Collapse;
const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2316353_u37sh1tvzmk.js',
});
export default function DashBoardPage(){
    const user = useSelector(state=>state.user)
    const [statics, setStatics] = useState({})
    const [statisticLoading, setStatisticLoading] = useState(true)
    const [data, setData] = useState([])
    // const [visible, setVisible] = useState(false)
    // const [delay, setDelay] = useState(localStorage.getItem('etcAdShow')?null:1000)
    // const [count, setCount] = useState(5)

    useEffect(()=>{
        authGetData('/api/statics', user.token)
            .then((data)=>{
                if(data.status === 'success'){
                    setStatics({...data})
                }
                setStatisticLoading(false)
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
        
        authGetData('/api/posts/page/1', user.token)
            .then((data) => {
                setData(data.items)
            })

    },[user.token])


    function createMarkup(str) {
        return { __html: str };
    }
    
    const afterClose = ()=>{
        localStorage.setItem('etcAdShow', true);
    }

    return (
        <>
            {/* <Modal
                title={null}
                centered
                width="600"
                closable={count <= 0}
                visible={visible}
                footer={null}
                onCancel={()=>{setVisible(false)}}
                maskClosable={count <= 0}
                afterClose={afterClose}
            >
                {count>0 && 
                    <div style={{position:'absolute',top:10,right:20}}>{count}秒后可关闭</div>
                }
                <div style={{'padding':10}}>
                    <img src={process.env.PUBLIC_URL + '/feed.png'} alt="close" />
                </div>
            </Modal> */}
            <Card style={{marginBottom:20}}>
                <Row>
                    <Col flex={3}>
                        <Title level={3}>{user.username} 您好!</Title>
                        <p>欢迎使用【智运大数据】</p>
                    </Col>
                    <Col flex={1}>
                        <Statistic loading={statisticLoading} title="开户日期" value={statics.start_time} />
                    </Col>
                    <Col flex={1}>
                        <Statistic loading={statisticLoading} title="已使用" value={statics.using_days+'天'} />
                    </Col>
                </Row>

            </Card>
            <div className="site-statistic-demo-card">
                <Row gutter={16}>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                loading={statisticLoading}
                                title={
                                    <>
                                        <Space>
                                            <IconFont style={{fontSize:18}} type="iconyue" />
                                            <span>余额</span>
                                        </Space>
                                        <Button type="primary" size="small" style={{float:'right'}}>
                                            <Link to="/user/topup">充值</Link>
                                        </Button>
                                    </>
                                }
                                value={statics.balance} 
                                precision={2} 
                            />
                        </Card>
                    </Col>
                    {/* <Col span={6}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconyundanzongshu" />运单总数</Space>} value={statics.order_count} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconjiaoyibishu" />交易笔数</Space>} value={statics.moneyrecord_count} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconfapiaozhangshu" />发票张数</Space>} value={statics.invoice_count} />
                        </Card>
                    </Col> */}
                </Row>
                <Row gutter={16}>
                    <Col span={6} style={{ marginTop: 16 }}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconyixiaofeijine" />已消费金额</Space>} value={statics.amount ? statics.amount : 0} precision={2} />
                        </Card>
                    </Col>
                    {/* <Col span={6} style={{ marginTop: 16 }}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconkedikoushuie" />可抵税额</Space>} value={statics.se ? statics.se : 0} precision={2} />
                        </Card>
                    </Col>
                    <Col span={6} style={{ marginTop: 16 }}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconqiyesuodeshui" />已开出发票总金额</Space>} value={statics.jshj ? statics.jshj : 0} precision={2} />
                        </Card>
                    </Col>
                    <Col span={6} style={{ marginTop: 16 }}>
                        <Card>
                            <Statistic loading={statisticLoading} title={<Space><IconFont style={{ fontSize: 18 }} type="iconchenggongshuai" />开票成功率(实时/历史)</Space>} value={statics.rate_suc} precision={2} />
                        </Card>
                    </Col> */}
                    {user.is_admin &&
                        <Col span={6} style={{ marginTop: 16 }}>
                            <Card>
                            <Statistic loading={statisticLoading} valueStyle={{ color: '#ff7a45' }} title="平台总余额" value={statics.total_balance} precision={2} />
                            </Card>
                        </Col>
                    }
                    {/* {user.is_admin &&
                        <Col span={6} style={{ marginTop: 16 }}>
                            <Card>
                            <Statistic loading={statisticLoading} valueStyle={{ color: '#ff7a45' }} title="昨日新增运单" value={statics.yesterday_order_count} />
                            </Card>
                        </Col>
                    } */}
                    {user.is_admin &&
                        <Col span={6} style={{ marginTop: 16 }}>
                            <Card>
                            <Statistic loading={statisticLoading} valueStyle={{ color: '#ff7a45' }} title="总开户数" value={statics.total_user} />
                            </Card>
                        </Col>
                    }
                    {user.is_admin &&
                        <Col span={6} style={{ marginTop: 16 }}>
                            <Card>
                            <Statistic loading={statisticLoading} valueStyle={{ color: '#ff7a45' }} title="总开户费" value={statics.total_service_fee} />
                            </Card>
                        </Col>
                    }
                    {user.is_admin &&
                        <Col span={6} style={{ marginTop: 16 }}>
                            <Card>
                            <Statistic loading={statisticLoading} valueStyle={{ color: '#ff7a45' }} title="欠费用户" value={statics.overdue_user_count} />
                            </Card>
                        </Col>
                    }
                    {user.is_admin &&
                        <Col span={6} style={{ marginTop: 16 }}>
                            <Card>
                            <Statistic loading={statisticLoading} valueStyle={{ color: '#ff7a45' }} title="欠费总额" value={statics.overdue_user_amount} />
                            </Card>
                        </Col>
                    }
                </Row>
            </div>
            <Divider />
            <Title level={3}>系统通知</Title>
            <Collapse defaultActiveKey="5" accordion bordered={false}>
                {data.map(post => {
                    return (
                        <Panel header={post.title} key={post.id} extra={<div><span style={{ color:'#8c8c8c' }}>{post.publish_date}</span>{user.is_admin && <Link style={{marginLeft:10}} to={`/post?id=${post.id}`}>编辑</Link>}</div>}>
                            <div dangerouslySetInnerHTML={createMarkup(post.content)}></div>
                        </Panel>
                    )
                })}
                
            </Collapse>
        </>
    )
}