import React, { useState } from 'react'
import { notification, Descriptions } from 'antd';

import TrackingSearchForm from './forms/TrackingSearchForm'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import 'moment/locale/zh-cn';

export default function TrackingQuery(props) {
    const [item, setItem] = useState(null)
    const location = useLocation()
    const tracking_type = location.pathname.match(/^\/tracking\/(\w+)$/)[1]

    const onFinish = (result) => {

        if(tracking_type === 'innet'){
            notification.success({
                message: '查询结果',
                description: <div>{result === 'yes' ? '该车辆已在全国货运平台入网' :'该车辆未入网'}</div>,
                duration: 0
            })
        } else if (tracking_type === 'exist'){
            if(result === 'no'){
                notification.error({
                    message: '查询结果',
                    description: '该车辆未在全国道路货运车辆公共监管与服务平台入网',
                    duration: 0
                })
            }else{
                notification.success({
                    message: '查询结果',
                    description: <div><p>该车辆已在全国道路货运车辆公共监管与服务平台入网</p><p>最新定位时间:{moment(Number(result)).format('YYYY-MM-DD HH:mm:ss')}</p></div>,
                    duration: 0
                })
            }
        } else if (tracking_type === 'vehicle_license'){
            setItem(result)
        } else if (tracking_type === 'trans_license') {
            setItem(result)
        }
    }

    
    return (
        <>
            <TrackingSearchForm onFinish={onFinish} />
            {(item && tracking_type === 'vehicle_license') && 
                <Descriptions
                    title="行驶证查询结果"
                    bordered
                    column={3}
                >
                    <Descriptions.Item label="联系人">{item.vclWnrNm}</Descriptions.Item>
                    <Descriptions.Item label="联系人手机">{item.vclWnrPhn}</Descriptions.Item>
                    <Descriptions.Item label="省编码">{item.province}</Descriptions.Item>
                    <Descriptions.Item label="市编码">{item.city}</Descriptions.Item>
                    <Descriptions.Item label="县">{item.country}</Descriptions.Item>
                    <Descriptions.Item label="县编码">{item.ctyCode}</Descriptions.Item>
                    <Descriptions.Item label="车辆类型">{item.vclTpNm}</Descriptions.Item>
                    <Descriptions.Item label="车辆品牌">{item.vbrndCdNm}</Descriptions.Item>
                    <Descriptions.Item label="车辆型号">{item.prdCdNm}</Descriptions.Item>
                    <Descriptions.Item label="车主/业户">{item.cmpNm}</Descriptions.Item>
                    <Descriptions.Item label="车辆识别代码/车架号">{item.vin}</Descriptions.Item>
                    <Descriptions.Item label="总质量/准牵引总质量">{item.vclTon}</Descriptions.Item>
                    <Descriptions.Item label="核定载重量">{item.ldTn}</Descriptions.Item>
                    <Descriptions.Item label="外廓尺寸-长(mm)">{item.vclLng}</Descriptions.Item>
                    <Descriptions.Item label="外廓尺寸-宽(mm)">{item.vclWdt}</Descriptions.Item>
                    <Descriptions.Item label="外廓尺寸-高(mm)">{item.vclHgt}</Descriptions.Item>
                    <Descriptions.Item label="内廓尺寸-长(mm)">{item.boxLng}</Descriptions.Item>
                    <Descriptions.Item label="内廓尺寸-宽(mm)">{item.boxWdt}</Descriptions.Item>
                    <Descriptions.Item label="内廓尺寸-高(mm)">{item.boxHgt}</Descriptions.Item>
                    <Descriptions.Item label="行驶证发证日期">{item.drvLicDt}</Descriptions.Item>
                    <Descriptions.Item label="行驶证有效期">{item.drvLicVdy && moment(Number(item.drvLicVdy)).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                    <Descriptions.Item label="车轴数">{item.vehicleAxis}</Descriptions.Item>
                </Descriptions>
            }
            {(item && tracking_type === 'trans_license') && 
                <Descriptions
                    title="道路运输证信息"
                    bordered
                    column={1}
                >
                    <Descriptions.Item label="道路运输证号码">{item.roadTransport}</Descriptions.Item>
                    <Descriptions.Item label="有效期">{item.vdtTm}</Descriptions.Item>
                </Descriptions>
            }
        </>
    )
}