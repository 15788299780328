import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { postData } from '../../service';
import GeeTestComponent from "../GeeTest";

export default function PotentialForm(props) {
    const [form] = Form.useForm();
    const [data, setData] = useState(null)
    const onFinish = values => {

        if (!data) {
            message.info('请先点击验证按钮进行验证')
            return
        }

        postData('/api/pc-geetest/ajax_validate', {
            ...data
        }).then((result)=>{
            if(result.status === 'success'){
                postData(`/api/inbox/potential`, {
                    ...values
                }).then(data => {
                    if (data.status === 'success') {
                        message.success(data.msg)
                    } else {
                        message.error(data.msg)
                    }
                }).catch(p => {
                    if (!p) {
                        message.error('服务器出错');
                        return
                    }
        
                    p.then(data => {
                        message.error(data.message)
                    })
                })
            }else{
                message.error('验证重复')
            }
            
            props.onFinished(result)
        })
    }

    const gtCallback = (data) => {
        setData(data)
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <h3>申请试用</h3>
            <p style={{marginBottom: 40}}>提交资料3个工作日内业务员将联系您</p>

            <Form.Item name="company_name" rules={[{ required: true, message: '请输入公司名称' }]}>
                <Input autoComplete="off" placeholder="公司名称" />
            </Form.Item>
            <Form.Item name="user_name" rules={[{ required: true, message: '请输入您的姓名' }]}>
                <Input autoComplete="off" placeholder="您的姓名" />
            </Form.Item>
            <Form.Item name="contact" rules={[{ required: true, message: '请输入您的电话' }]}>
                <Input autoComplete="off" placeholder="您的电话" />
            </Form.Item>
            <Form.Item>
                <GeeTestComponent validate={gtCallback}></GeeTestComponent>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" block>
                    立即申请
                </Button>
            </Form.Item>
        </Form>
    )
}