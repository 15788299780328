import React, {  useEffect, useState } from 'react'
import { Table, message, Button, Divider, Row, Col, Statistic, Tooltip, Tag } from 'antd'
import { authGetData, authPostData } from '../../service';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Modal from 'antd/lib/modal/Modal';

export default function FinanceIndexPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [address, setAddress] = useState(null)
    const [invoice, setInvoice] = useState(null)
    const [totalAmount, setTotalAmount] = useState(null)
    const [faPiao, setFaPiao] = useState(null)
    const [visible, setVisible] = useState(false)

    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    const user = useSelector(state=>state.user)
    const history = useHistory()

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    const getInvoicingFp = (id) => {
        authGetData(`/api/invoicing/fp/${id}`, user.token)
            .then(data => {
                if(data.status === 'success'){
                    setFaPiao(data.item)
                    setVisible(true)
                    return
                }
                message.error(data.msg)
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: '90px'
        },
        {
            title: '用户',
            dataIndex: 'title',
        },
        {
            title: '开票金额',
            dataIndex: 'fee',
        },
        {
            title: '发票性质',
            dataIndex: 'type',
        },
        {
            title: '邮寄地址',
            dataIndex: 'address',
            width: '15%'
        },
        {
            title: '开票信息',
            dataIndex: 'invoice_info',
            width: '20%'
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                let tagName;
                let color;
                if (status === 0) {
                    tagName = '待开票'
                    color = 'processing'
                } else if (status === 1) {
                    tagName = '已开票'
                    color = 'success'
                } else if (status === 2) {
                    tagName = '已撤销'
                    color = 'red'
                }

                return (
                    <Tag color={color} key={status}>
                        {tagName}
                    </Tag>
                )
            }
        },
        {
            title: '详情',
            dataIndex: 'detail'
        },
        {
            title: '申请时间',
            dataIndex: 'last_update',
        },
        {
            title: '操作',
            render: (text, record, index) => {
                if (record.status===1){
                    return <Button type="primary" ghost onClick={getInvoicingFp.bind(this, record.id)}>详情</Button>
                }
                return 
            }

        }
    ];

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    useEffect(()=>{
        authGetData(`/api/invoices/apply/page/${pagination.current}`, user.token)
            .then(data => {
                if (data == null) return;
                setLoading(false)
                setData(data.items)
                setTotalAmount(data.total_amount)
                setPagination({
                    ...pagination,
                    total: data.total
                })
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [pagination.current, user.token]);

    useEffect(() => {
        authGetData(`/api/invoicing/invoice_info`, user.token)
            .then(data => {
                if (data == null) return;
                if(data.status === 'success'){
                    setInvoice(data.item)
                }

            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })

    }, [user.token]);
    
    useEffect(() => {
        authGetData(`/api/invoicing/address`, user.token)
            .then(data => {
                if (data == null) return;
                if(data.status === 'success'){
                    setAddress(data.item)
                }

            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }, [user.token]);
    
    const applyForInvoice = ()=>{
        if(invoice == null){
            message.error('无开票信息, 请先添加')
            return
        }

        if(address == null){
            message.error('无地址信息, 请先添加')
            return
        }

        history.push('/apply_for_invoice')
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={6}>
                    <Statistic title="可开票金额" value={totalAmount == null ? '无可开发票' : totalAmount} precision={2} />
                    <Tooltip placement="right" title="金额大于1000,可申请开票" style={{ marginTop: 16 }} color="green">
                        <Button onClick={applyForInvoice} type="primary" disabled={(totalAmount == null || totalAmount<1000)?true:false}>
                            申请开票
                        </Button>
                    </Tooltip>
                </Col>
                <Col span={8}>
                    <Statistic className="address-statistic" title="开票信息" value={invoice==null?'暂无开票信息':invoice.invoice_title} />
                    <Button style={{ marginTop: 16 }} type="primary" ghost>
                        <Link to="/invoice_info">管理开票信息</Link>
                    </Button>
                </Col>
                <Col span={8}>
                    <Statistic className="address-statistic" title="邮寄地址" value={address == null ? '暂无地址信息' : address.recipient + ' ' + address.contact_number + ' ' + address.address} />
                    <Button style={{ marginTop: 16 }} type="primary" ghost>
                        <Link to="/manage_address">管理邮寄地址</Link>
                    </Button>
                </Col>
            </Row>
            <Divider></Divider>
            <h3>申请记录</h3>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                locale={{emptyText:'暂无开票申请记录'}}
            />
            <Modal
                title='发票及快递信息'
                visible={visible}
                onCancel={()=>setVisible(false)}
                footer={null}
            >   
            {faPiao!==null && 
                <div>
                    <p>发票代码: {faPiao.fpdm}</p>
                    <p>发票号码: {faPiao.fphm}</p>
                    <p>快递单号: {faPiao.express_number}</p>
                </div>
            }
            </Modal>
        </>
    )
}