import React, { useState } from "react";
import { Form, Input, InputNumber, Button, message, Switch } from 'antd';

import { useSelector } from "react-redux";
import { authPostData } from '../../service'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export default function ApiPriceForm(props){
    const [form] = Form.useForm();
    const user = useSelector(state=>state.user)
    const [btnLoading, setBtnLoading] = useState(false)

    const onFinish = values => {
        let url = `/api/tracking/api`

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...values
        }).then(data=>{
            if(data.status === 'success'){
                message.success(data.msg);
            }else{
                message.error(data.msg);
            }
            setBtnLoading(false)

        })
        .catch((p) => {
            p.then(data => {
                message.error(data.message);
            })

            if (!p) {
                message.error('服务器出错');
            }
            setBtnLoading(false)
        })
    };

    const initialValues = {
        time_price: 0.5,
        day_price: 1,
        month_price: 10,
    }


    return (
        <Form {...layout} form={form} name="control-hooks" initialValues={initialValues} onFinish={onFinish} >
            <Form.Item name="name" label="API名称" rules={[{ required: true, message:"请填写API名称"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="api" label="API路径" rules={[{ required: true, message:"api"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="time_price" label="每笔扣费" rules={[{ required: true }]}>
                <InputNumber
                    min={0}
                    max={100}
                />
            </Form.Item>
            <Form.Item name="day_price" label="每天扣费" rules={[{ required: true }]}>
                <InputNumber
                    min={0}
                    max={100}
                />
            </Form.Item>
            <Form.Item name="month_price" label="每月扣费" rules={[{ required: true }]}>
                <InputNumber
                    min={0}
                    max={100}
                />
            </Form.Item>
            <Form.Item name="is_step" label="是否阶梯计费">
                <Switch />
            </Form.Item>
            <Form.Item name="step_price" label="计费规则">
                <Input.TextArea />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={btnLoading}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    )
}