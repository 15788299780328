import React, {  useEffect, useState } from 'react'
import { Drawer, Table, Tag, message, Button, Modal, Descriptions, Popconfirm, Tabs } from 'antd'
import { authGetData, authDeleteData, authPostData } from '../service';
import { useSelector } from 'react-redux';
import OrderEndForm from './forms/OrderEndForm'
import OrderSearchForm from './forms/OrderSearchForm'
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const { TabPane } = Tabs;

export default function OrdersPage(){
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [currentRow, setCurrentRow] = useState({})
    const [data, setData] = useState([])
    const [invoice, setInvoice] = useState([])
    const [searchMode, setSearchMode] = useState(false)
    const [fieldsValue, setFieldsValue] = useState({})
    const [trade, setTrade] = useState({})
    const [activeKey, setActiveKey] = useState('1')
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })
    const user = useSelector(state=>state.user)
    let location = useLocation()
    let history = useHistory()

    let handleTableChange = (_pagination)=>{
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    let handlerDelete = (id, index)=>{
        authDeleteData(`/api/order/${id}`, user.token)
            .then(reps_data=>{
                let _data = data.slice()
                _data.splice(index, 1)
                message.success(reps_data.msg)
                setData(_data)               
            })
            .catch(p => {
                p.then(data => {
                    message.error(data.msg)
                })
            })
    }

    const hanlderInvoiceCheck = (id, index) => {
        const hide = message.loading('正在查询发票信息, 请耐心等待', 0);
        authGetData(`/api/order/${id}/invoice`, user.token)
            .then(reps_data => {
                if(reps_data.status==='success'){
                    let row = data[index]
                    setInvoice(reps_data.items)
                    setVisible(true)
                    let hash = {};
                    let se = 0;
                    let jshj = 0;
                    reps_data.items.forEach(item => {
                        if (!hash[item.trade_id]) {
                            hash[item.trade_id] = item
                        }
                        se += Number(item.se)
                        jshj += Number(item.jshj)
                    });
                    
                    setTrade(hash);
                    row.status = 2;
                    row.se = se.toFixed(2);
                    row.jshj = jshj.toFixed(2);

                    setCurrentRow(row)
                }else{
                    message.error(reps_data.msg)
                }
                hide()
            })
            .catch(p => {
                setVisible(false)
                hide()
                if (!p) {
                    message.error('服务器出错');
                    return
                }
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const onClose = () => {
        setVisible(false)
        setActiveKey('1')
    };

    const activeKeyChange = key =>{
        setActiveKey(key)
    }

    const onFinished = (end_time) => {
        let row = currentRow
        row.end_time = end_time
        row.status = 1
        setCurrentRow(row)
        setVisible(false);
        setVisibleModal(false);
    }

    const handlerEdit = (id, index) => {
        setVisibleModal(true)
        setCurrentRow(data[index])
    }

    const searchResult = (data, _fieldsValue) => {
        setData(data.items)
        setSearchMode(true)
        setFieldsValue(_fieldsValue)
        setPagination({
            ...pagination,
            current: 1,
            total: data.total
        })
    }

    const searchClear = () => {
        let filter = location.pathname.match(/^\/orders\/(\w+)$/)[1]
        setSearchMode(false)
        history.replace(`/orders/${filter}`)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: '90px'
        },
        {
            title: '运单编号',
            dataIndex: 'num',
            width: '10%'
        },
        {
            title: '车牌号',
            dataIndex: 'plate_number',
            width: '8%',
        },
        {
            title: '开始时间',
            dataIndex: 'start_time',
        },
        {
            title: '起始地',
            dataIndex: 'source_addr',
        },
        {
            title: '实际目的地',
            dataIndex: 'real_dest_addr',
        },
        {
            title: '预计结束时间',
            dataIndex: 'predict_end_time',
        },
        {
            title: '结束时间',
            dataIndex: 'end_time',
        },
        {
            title: '类型',
            dataIndex: 'order_type',
            width: '6%',
            render: (order_type)=>{
                return (
                    <Tag key={order_type}>
                        {order_type===0?
                            '实时运单':
                            order_type===1? '历史运单': '暂存运单'
                        }
                    </Tag>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: '5%',
            render: (status)=>{
                let tagName;
                let color;
                if(status === 0){
                    tagName = '未结束'
                    color = 'magenta'
                }else if(status === 1){
                    tagName = '已结束'
                    color = 'geekblue'
                }else if(status === 2){
                    tagName = '已开票'
                    color = 'orange'
                }
                return (
                    <Tag color={color} key={status}>
                        {tagName}
                    </Tag>
                )
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <>
                    {record.status !==0 && <Button type="link" size="small" onClick={hanlderInvoiceCheck.bind(this,record.id, index)}>查看发票</Button>}
                    {record.status === 0 && <Button type="primary" size="small" onClick={handlerEdit.bind(this, record.id, index)}>结束运单</Button>}
                    {user.is_admin && 
                        <Popconfirm title="Are you sure？" okText="确定" cancelText="取消" onConfirm={handlerDelete.bind(this, record.id, index)}>
                            <Button type="link" danger>删除</Button>
                        </Popconfirm>
                    }
                </>
            ),
        },
    ];

    if(user.is_admin){
        columns.splice(1,0,{
            title: '用户ID',
            dataIndex: 'user_id',
            width: '90px'
        })
    }

    if(user.is_admin || user.is_salesman){
        columns.splice(3, 0, {
            title: '运单更新时间',
            dataIndex: 'last_update',
        })
    }

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    useEffect(()=>{
        let filter = location.pathname.match(/^\/orders\/(\w+)$/)[1]
        setLoading(true)
        if(searchMode){
            authPostData(`/api/orders/search/page/${pagination.current}`, user.token, {
                ...fieldsValue
            })
                .then(data => {
                    setLoading(false)
                    setData(data.items)
                    setPagination({
                        ...pagination,
                        total: data.total
                    })
                })
                .catch(p => {
                    if (user.token === '') return
                    p.then(data => {
                        message.error(data.message)
                    })
                })
        }else{
            authGetData(`/api/orders/page/${pagination.current}/${filter}`, user.token)
                .then(data=>{
                    setLoading(false)
                    setData(data.items)
                    setPagination({
                        ...pagination,
                        total: data.total
                    })
                })
                .catch(p=>{
                    if (user.token === '') return
                    p.then(data=>{
                        console.log(data)
                        message.error(data.message)
                    })
                })
        }
    }, [pagination.current, user.token, location]);

    

    return (
        <>
            {location.pathname.match(/^\/orders\/(\w+)$/)[1]!=='invoiced' &&
                <OrderSearchForm onFinish={searchResult} onClear={searchClear} />
            }
            <Table
                scroll={{
                    x: 1400
                }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Drawer
                width={750}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Tabs activeKey={activeKey} onChange={activeKeyChange} size='large'>
                    <TabPane tab={`发票数(${invoice.length})`} key="1">
                        {invoice.map((item, index)=>{
                            return (
                                <Descriptions size='small' title={`发票${index + 1}`} key={item.invoiceid}>
                                    <Descriptions.Item label="发票代码">{item.fpdm}</Descriptions.Item>
                                    <Descriptions.Item label="开票日期">{item.kprq}</Descriptions.Item>
                                    <Descriptions.Item label="入口 - 出口">{item.enter} - {item.exit}</Descriptions.Item>
                                    <Descriptions.Item label="发票号码">{item.fphm}</Descriptions.Item>
                                    <Descriptions.Item label="发票地址">
                                        <a rel="noopener noreferrer" target="_blank" href={`https://www.zy.zone/static/download_invoice_folder/${item.fpdm}_${item.fphm}.pdf`}>查看发票</a>
                                    </Descriptions.Item>
                                </Descriptions>
                            )
                        })
                        }
                    </TabPane>
                    <TabPane tab={`路网交易数(${Object.keys(trade).length})`} key="2">
                        {Object.values(trade).map((item,index)=>{
                            return (
                                <Descriptions column={1} size='small' title={`交易${index + 1}`} key={item.invoiceid}>
                                    <Descriptions.Item label="入口 - 出口">{item.enter} - {item.exit}</Descriptions.Item>
                                    <Descriptions.Item label="交易号">{item.trade_id}</Descriptions.Item>
                                </Descriptions>
                            )
                        })
                        }
                    </TabPane>
                    <TabPane tab={`发票数据统计`} key="3">
                        <h3>价税合计: {currentRow.jshj}</h3>
                        <h3>可抵税额: {currentRow.se}</h3>
                    </TabPane>
                </Tabs>
            </Drawer>
            <Modal
                title="编辑订单"
                visible={visibleModal}
                footer={null}
                onCancel={() => setVisibleModal(false)}
            >
                <OrderEndForm data={currentRow} onFinished={onFinished} />
            </Modal>
        </>
    )
}