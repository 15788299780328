import React from "react";
import { Card, Space, Row, Col } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { Meta } = Card;


export default function InsuranceIndexPage(){
    
    return (
        <Row justify="start">
            <Col span={8} xxl={6}>
                <Card
                    style={{ width: '95%' }}
                    cover={
                        <img alt="保险1" src={process.env.PUBLIC_URL + '/cbx.png'} />
                    }
                    actions={[
                        <Link to='/insurance/danpiao'>立即投保</Link>
                    ]}
                >
                    <Meta
                        title="单票货运险"
                        description="投保快捷，费率低至0.02%起，普货、冷藏、果蔬、易碎、大件均可承保"
                    />
                </Card>
            </Col>
            <Col span={8} xxl={6}>
                <Card
                    style={{ width: '95%' }}
                    cover={
                        <img alt="保险1" src={process.env.PUBLIC_URL + '/gz.png'} />
                    }
                    actions={[
                        <Link to='/insurance/guzhu'>立即投保</Link>
                    ]}
                >
                    <Meta
                        title="雇主责任险"
                        description="保障员工安全，可扩展24小时，保额多种选择，十级伤残赔付比例10%起"
                    />
                </Card>
            </Col>
            <Col span={8} xxl={6}>
                <Card
                    style={{ width: '95%' }}
                    cover={
                        <img alt="保险1" src={process.env.PUBLIC_URL + '/ckbx.png'} />
                    }
                    actions={[
                        <Link to='/insurance/nianbao'>立即投保</Link>
                    ]}
                >
                    <Meta
                        title="年保责任险"
                        description="一单保全年，根据单次限额、营业额灵活选择保险方案；也有预约年保，按次申报"
                    />
                </Card>
            </Col>
        </Row>
    )
}