import React, {  useEffect, useState } from 'react'
import { Table, message, Button, Space, Tag, Modal, Drawer, Descriptions } from 'antd'
import { authGetData, authPostData } from '../service';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import RelationForm from './forms/RelationForm'
import UserSearchForm from './forms/UserSearchForm'
import UserGiftDaysForm from './forms/UserGiftDaysForm';
import UserRestrictForm from './forms/UserRestrictForm';

export default function UsersPage(){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const user = useSelector(state=>state.user)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleGiftModal, setVisibleGiftModal] = useState(false)
    const [visibleRestrictModal, setVisibleRestrictModal] = useState(false)
    const [currentRow, setCurrentRow] = useState({})
    const [searchMode, setSearchMode] = useState(false)
    const [fieldsValue, setFieldsValue] = useState({})
    const [drawVisible, setDrawVisible] = useState(false);
    const [currentView, setCurrentView] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        defaultPageSize: 20,
        showTotal: showTotal,
        showSizeChanger: false
    })

    let history = useHistory();
    let handlerClick = (id, index)=>{
        history.push(`/topup`, { id })
    }

    let handlerRelative = (record)=>{
        setVisibleModal(true)
        setCurrentRow(record)
    }

    let handlerGift = (record)=>{
        setVisibleGiftModal(true)
        setCurrentRow(record)
    }
    
    let handlerRestrict = (record)=>{
        setVisibleRestrictModal(true)
        setCurrentRow(record)
    }

    let handleTableChange = (_pagination) => {
        setPagination({
            ...pagination,
            current: _pagination.current
        })
    }

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: 65
        },
        {
            title: '用户名',
            dataIndex: 'username',
            fixed: 'left',
            width: 200,
            render: (text, record, index) => (
                <Space>
                    <span>{record.username}</span>
                    {record.is_admin &&
                        <Tag color="orange">管理员</Tag>
                    }
                    {record.is_agent ?
                        <Tag color="volcano">渠道商</Tag> :
                        record.is_salesman &&
                        <Tag color="red">业务员</Tag>
                    }
                </Space>
            ),
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 150
        },
        {
            title: '账户余额',
            dataIndex: 'balance',
            sorter: (a, b) => a.balance - b.balance
        },
        {
            title: '开户日期',
            dataIndex: 'last_update'
        },
        {
            title: '到期日期',
            dataIndex: 'expire_date'
        },
        
        
    ];

    if(user.is_admin){
        columns.push({
            title: '业务员',
            dataIndex: 'salesman'
        })
        
        columns.push({
            title: '开户费',
            dataIndex: 'opening_fee',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.opening_fee - b.opening_fee
        })
        
        columns.push({
            title: '操作',
            key: 'action',
            width: '360px',
            render: (text, record, index) => (
                <Space>
                    <Button size="small" type="primary" onClick={handlerClick.bind(this, record.id, index)}>充值</Button>
                    <Button size="small" type="primary" ghost onClick={viewDetail.bind(this, record)}>详情</Button>
                    <Button size="small" type="default" onClick={handlerRelative.bind(this, record)}>关联业务员</Button>
                    <Button size="small" type="default" onClick={handlerGift.bind(this, record)}>赠送</Button>
                    <Button size="small" type="default" onClick={handlerRestrict.bind(this, record)}>限制用户</Button>
                </Space>
            ),
        })
    }

    useEffect(()=>{
        setLoading(true)
        if (searchMode) {
            authPostData(`/api/users/search/page/${pagination.current}`, user.token, {
                ...fieldsValue
            })
                .then(data => {
                    setLoading(false)
                    setData(data.items)
                    setPagination({
                        ...pagination,
                        total: data.total
                    })
                })
                .catch(p => {
                    if (user.token === '') return
                    p.then(data => {
                        message.error(data.message)
                    })
                })
        } else {
            authGetData(`/api/users/page/${pagination.current}`, user.token)
                .then(data => {
                    setLoading(false)
                    setData(data.items)
                    setPagination({
                        ...pagination,
                        total: data.total
                    })
                })
                .catch(p => {
                    if (user.token === '') return
                    p.then(data => {
                        console.log(data)
                        message.error(data.message)
                    })
                })
        }

    }, [pagination.current, user.token]);

    const onFinished = (obj) => {
        currentRow.salesman = obj.salesman

        setVisibleModal(false)
        setCurrentRow(currentRow)
    }

    const onGiftFinished = (obj) => {
        currentRow.expire_date = obj.expire_date

        setVisibleGiftModal(false)
        setCurrentRow(currentRow)
    }

    const onRestictFinished = (obj) => {
        currentRow.is_restrict = obj.is_restrict

        setVisibleRestrictModal(false)
        setCurrentRow(currentRow)
    }

    const searchResult = (data, _fieldsValue) => {
        console.log(data)
        setData(data.items)
        setSearchMode(true)
        setFieldsValue(_fieldsValue)
        setPagination({
            ...pagination,
            current: 1,
            total: data.total
        })
    }

    const searchClear = () => {
        console.log('clear')
        setSearchMode(false)
        setPagination({
            ...pagination,
            current: 1,
        })
    }

    const showDrawer = () => {
        setDrawVisible(true);
    };
    const onClose = () => {
        setDrawVisible(false);
    };

    const viewDetail = (record, index) => {
        message.loading('正在获取用户信息', 1.2)
        authGetData(`/api/user/${record.id}/detail`, user.token)
            .then(data => {
                if(data.status==='success'){
                    record = Object.assign({},record, data.item)
                    showDrawer()
                    setCurrentView(record)
                }else{
                    message.error(data.msg)
                }
            })
            .catch(p => {
                if (user.token === '') return
                p.then(data => {
                    console.log(data)
                    message.error(data.message)
                })
            })
    }

    return (
        <>
            <UserSearchForm onFinish={searchResult} onClear={searchClear} />
            <Table
                columns={columns}
                pagination={pagination}
                rowKey={record => record.id}
                dataSource={data}
                loading={loading}
                onChange={handleTableChange}
                scroll={{ x: 1500 }}
                onRow={record => {
                    return {
                        onDoubleClick: event => { viewDetail(record) },
                    }
                }}
            />
            <Modal
                title="关联业务员"
                visible={visibleModal}
                footer={null}
                onCancel={() => setVisibleModal(false)}
            >
                <RelationForm data={currentRow} onFinished={onFinished} />
            </Modal>
            <Modal
                title="赠送天数"
                visible={visibleGiftModal}
                footer={null}
                onCancel={() => setVisibleGiftModal(false)}
            >
                <UserGiftDaysForm data={currentRow} onFinished={onGiftFinished} />
            </Modal>
            <Modal
                title="限制用户"
                visible={visibleRestrictModal}
                footer={null}
                onCancel={() => setVisibleRestrictModal(false)}
            >
                <UserRestrictForm data={currentRow} onFinished={onRestictFinished} />
            </Modal>
            <Drawer
                title="用户详情"
                placement="right"
                onClose={onClose}
                visible={drawVisible}
                width="550"
            >
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="用户名">{currentView.username}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{currentView.mobile}</Descriptions.Item>
                    <Descriptions.Item label="账户余额">{currentView.balance}</Descriptions.Item>
                    <Descriptions.Item label="开户日期">{currentView.last_update}</Descriptions.Item>
                    <Descriptions.Item label="到期日期">{currentView.expire_date}</Descriptions.Item>
                    <Descriptions.Item label="ETC每笔收费">{currentView.per_transaction}</Descriptions.Item>
                    <Descriptions.Item label="轨迹查询折扣率">{currentView.tracking_discount}</Descriptions.Item>
                    <Descriptions.Item label="运单总数(不含未完成)">{currentView.order_count}</Descriptions.Item>
                    <Descriptions.Item label="已开票运单数(实时/历史)">{currentView.kp_count}</Descriptions.Item>
                    <Descriptions.Item label="成功率(实时/历史)">{currentView.rate_suc}</Descriptions.Item>
                    <Descriptions.Item label="可申请开票金额">{currentView.ke_kai_piao_amount}</Descriptions.Item>
                    {!user.is_agent &&
                        <Descriptions.Item label="业务员">{currentView.salesman}</Descriptions.Item>
                    }
                    {user.is_admin &&
                        <>
                        <Descriptions.Item label="开户费">{currentView.opening_fee}</Descriptions.Item>
                        <Descriptions.Item label="是否限制用户">{currentView.is_restrict?'是':'不是'}</Descriptions.Item>
                        </>
                    }
                </Descriptions>
            </Drawer>
        </>
    )
}