import React, { useState, useEffect } from "react";
import { Form, Button, message, Row, Col, notification, Select, DatePicker, Input } from 'antd';
import { useSelector } from "react-redux";
import { authPostData, authGetData } from '../../service';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
// const { RangePicker } = DatePicker;
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function RecordSearchForm(props) {
    const [form] = Form.useForm();
    const user = useSelector(state => state.user)
    const [btnLoading, setBtnLoading] = useState(false)
    const url = '/api/records/search/page/1'
    const [users, setUsers] = useState([])
    const [salesmans, setSalesmans] = useState([])

    const onFinish = values => {
        const fieldsValue = {
            ...values,
            time_range: values.time_range != null ? values.time_range.map(x => x.format('YYYY-MM-DD 00:00:00')) : null,

        }

        setBtnLoading(true)

        authPostData(url, user.token, {
            ...fieldsValue
        }).then(data => {
            if (data.status === 'success') {
                message.success(data.msg);
                if(data.total_sum){
                    notification.success({
                        message: '查询结果统计',
                        description: `共${data.total}条, 合计: ${data.total_sum}元`,
                        duration: 0
                    });
                }
                props.onFinish(data, fieldsValue)
            } else {
                message.error(data.msg);
            }
            setBtnLoading(false)
        })
            .catch((p) => {
                p.then(data => {
                    message.error(data.message);
                })

                if (!p) {
                    message.error('服务器出错');
                }

                setBtnLoading(false)
            })
    };

    useEffect(() => {
        if(user.is_admin || user.is_salesman){
            authGetData('/api/users', user.token)
            .then(data => {
                setUsers(data.items)
            })
        }
    }, [user.token])

    useEffect(() => {
        if(user.is_admin){
            authGetData('/api/all_salesman', user.token)
            .then(data => {
                setSalesmans(data.items)
            })
        }
    }, [user.token])

    return (
        <Form form={form} onFinish={onFinish} initialValues={{ type:'扣款' }} style={{
            maxWidth: '100%', background: '#fafafa',
            padding: '16px'}}>
            <Row gutter={16}>
                <Col span={5}>
                    <Form.Item style={{ marginBottom: 0 }} name="num" label="运单编号" >
                        <Input placeholder="请输入运单编号" allowClear />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name="type" label="充值/扣款" style={{ marginBottom: 0 }}>
                        <Select
                            placeholder="请选择充值还是扣款"
                        >
                            <Option value="充值">充值</Option>
                            <Option value="扣款">所有扣款</Option>
                            <Option value="运单扣款">运单扣款</Option>
                            <Option value="软件年费扣款">软件年费扣款</Option>
                            <Option value="车辆查询扣款">车辆查询扣款</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item style={{ marginBottom: 0 }} name="time_range" label="日期范围" >
                        <RangePicker locale={locale} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button type="primary" htmlType="submit" loading={btnLoading}>搜索</Button>
                    <Button
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                            form.resetFields();
                            props.onClear()
                        }}
                    >
                        清空
                    </Button>
                </Col>
            </Row>
            {(user.is_admin || user.is_salesman) &&
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item name="user" label="用户" style={{ marginBottom: 0,marginTop:20 }}>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="请选择用户"
                        >
                            {users.map(user =>
                                <Select.Option key={user.id} value={user.id}>{user.username}</Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                {user.is_admin &&
                    <Col span={8}>
                        <Form.Item name="salesman" label="业务员" style={{ marginBottom: 0, marginTop: 20 }}>
                            <Select 
                                allowClear 
                                showSearch
                                optionFilterProp="children"
                                placeholder="选择一个业务员">
                                {salesmans.map(u =>
                                    <Select.Option key={u.username} value={u.id}>{u.username}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                }
            </Row>
            }
        </Form>
    )
}