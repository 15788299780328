import React, { useEffect, useState, useRef } from 'react'
import TrackingSearchForm from './forms/TrackingSearchForm'
// import AMapLoader from '@amap/amap-jsapi-loader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { message, notification } from 'antd';

const styles = {
    myStyle: {
        width: 20, // 点标记样式宽度（像素）
        height: 30, // 点标记样式高度（像素）
        src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", //图片路径
        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
        anchor: { x: 10, y: 30 },
    },
    "car-down": {
        width: 40,
        height: 40,
        anchor: {
            x: 20,
            y: 20,
        },
        faceTo: "map",
        rotate: 180,
        src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png",
    },
    start: {
        width: 25,
        height: 35,
        anchor: { x: 16, y: 32 },
        src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png",
    },
    end: {
        width: 25,
        height: 35,
        anchor: { x: 16, y: 32 },
        src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png",
    },
};

export default function TrackingLocation(props) {
    const [map, setMap] = useState(null)
    // const [geo, setGeo] = useState(null)
    // const [address, setAddress] = useState(null)
    // const [amapInstance, setAmapInstance] = useState(null)
    const mapContainer = useRef(null)
    const [markLayer, setMarkLayer] = useState(null)
    const [lineLayer, setLineLayer] = useState(null)
    const location = useLocation()

    const onFinish = (result) => {
        
        if(location.pathname.indexOf('location')!==-1){
            let [lng, lat] = [(result.lon / 600000).toFixed(6), (result.lat / 600000).toFixed(6)]
            if(map){
                map.setCenter({
                    lng: lng,
                    lat: lat
                })

                map.zoomTo(11)

                if (markLayer) {
                    markLayer.setGeometries([]);
                }

                let _markLayer =new window.TMap.MultiMarker({
                    map: map,
                    styles: {
                        myStyle: new window.TMap.MarkerStyle(styles.myStyle),
                    },
                    geometries: [
                        {
                            id: 1,
                            styleId: "myStyle", //指定样式id
                            position: new window.TMap.LatLng(lat, lng), //点标记坐标位置
                            properties: {
                                //自定义属性
                                title: "当前位置",
                            },
                        },
                    ],
                });
                setMarkLayer(_markLayer)
            }
            
        } else if (location.pathname.indexOf('line')!==-1){
            let _arr = result.map(point => {
                return new window.TMap.LatLng((point.lat / 600000).toFixed(6), (point.lon / 600000).toFixed(6))
            })

            if(map) {
                let mid = Math.round(_arr.length/2)
                map.setCenter({
                    lng: _arr[mid].lng,
                    lat: _arr[mid].lat
                })
                map.zoomTo(9)

                if (markLayer) {
                    markLayer.setGeometries([]);
                }

                let _markLayer = new window.TMap.MultiMarker({
                    map: map,
                    styles: {
                        myStyle: new window.TMap.MarkerStyle(styles.myStyle),
                        start: new window.TMap.MarkerStyle(styles.start),
                        end: new window.TMap.MarkerStyle(styles.end),
                    },
                    geometries: [
                        {
                            id: 1,
                            styleId: "start", //指定样式id
                            position: new window.TMap.LatLng(_arr[0].lat, _arr[0].lng), //点标记坐标位置
                            properties: {
                                //自定义属性
                                title: "起点",
                            },
                        },
                        {
                            id: 2,
                            styleId: "end", //指定样式id
                            position: new window.TMap.LatLng(_arr[_arr.length-1].lat, _arr[_arr.length-1].lng), //点标记坐标位置
                            properties: {
                                //自定义属性
                                title: "终点",
                            },
                        }
                    ],
                });

                if (lineLayer) {
                    lineLayer.setGeometries([]);
                }

                let _ployline = new window.TMap.MultiPolyline({
                    map: map,
                    styles: {
                        styleRed: new window.TMap.PolylineStyle({
                            color: "#2269F2", // 线填充色
                            width: 6, // 折线宽度
                            borderColor: "#CCC", // 边线颜色
                            lineCap: "round", // 线端头方式
                        }),
                    },
                    geometries: [
                        {
                            paths: _arr,
                            styleId: "styleRed",
                        },
                    ],
                });

                setMarkLayer(_markLayer);
                setLineLayer(_ployline);
            }
            // let polyline = new amapInstance.Polyline({
            //     map: map,
            //     path: _arr,
            //     borderWeight: 2, // 线条宽度，默认为 1
            //     strokeColor: '#1890ff', // 线条颜色
            //     strokeWeight: 6,
            //     strokeOpacity: 1,
            //     lineJoin: 'round' // 折线拐点连接处样式
            // });

            // var passedPolyline = new amapInstance.Polyline({
            //     map: map,
            //     strokeColor: "#AF5",  //线颜色
            //     strokeWeight: 6,      //线宽
            // });
            
            // var marker_start = new amapInstance.Marker({
            //     position: _arr[0], 
            //     offset: new amapInstance.Pixel(-9, -25),
            //     // icon: icon_blue,
            //     content: '<div class="marker marker-start"><div class="mark-icon"></div><span>起点</span></div>',
            //     title: '起点'
            // });

            // var marker_end = new amapInstance.Marker({
            //     position: _arr.slice(-1)[0], 
            //     offset: new amapInstance.Pixel(-9, -25),
            //     content: '<div class="marker marker-end"><div class="mark-icon"></div><span>终点</span></div>',
            //     // icon: icon_red,
            //     title: '终点'
            // });

            // polyline.setMap(map)
            
            // map.add([marker_start, marker_end])
            // map.setFitView();

            // var car_marker = new amapInstance.Marker({
            //     map: map,
            //     position: _arr[0],
            //     icon: process.env.PUBLIC_URL + '/truck2.png',
            //     offset: new amapInstance.Pixel(-13, -26),
            // });

            // car_marker.on('moving', function (e) {
            //     passedPolyline.setPath(e.passedPath);
            // });

            // amapInstance.plugin('AMap.MoveAnimation', function () {
            //     car_marker.moveAlong(_arr, {
            //         duration: 20,
            //         autoRotation: true,
            //     });
            // });

        }
    }

    useEffect(() => {
        //定义map变量，调用 TMap.Map() 构造函数创建地图

        var map = new window.TMap.Map(mapContainer.current, {
            zoom: 8, //设置地图缩放级别
        });
        setMap(map)
    },[])

    // useEffect(()=>{
    //     AMapLoader.load({
    //         "key": "ad654435e8f98b1186025955bb5418ab", // 申请好的Web端开发者Key，首次调用 load 时必填
    //         "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    //         "plugins": ['AMap.Geocoder'],         // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    //         "AMapUI": {             // 是否加载 AMapUI，缺省不加载
    //             "version": '1.1',   // AMapUI 缺省 1.1
    //             "plugins": [],       // 需要加载的 AMapUI ui插件
    //         }
    //     }).then((AMap) => {
    //         setAmapInstance(AMap)
    //         setMap(new AMap.Map(mapContainer.current.id, {
    //             // isHotspot: true
    //         }))
    //         setGeo(new AMap.Geocoder())


    //     }).catch((e)=>{
    //         console.log(e)
    //     })

    //     return function cleanup() {
    //         map && map.destroy();
    //     }
    // }, [])

    // useEffect(()=>{
    //     if(geo){
    //         var marker = new amapInstance.Marker();
    //         map.on('click', function (e) {
    //             map.add(marker);
    //             let { lng, lat } = e.lnglat
    //             marker.setPosition([lng, lat]);
    //             geo.getAddress([lng, lat], function (status, result) {
    //                 if (status === 'complete' && result.regeocode) {
    //                     var address = result.regeocode.formattedAddress;
                        
    //                     notification.success({
    //                         message: '当前地址',
    //                         description: <div>{address} <a href={'https://www.baidu.com/s?wd='+address} target="_blank" rel="noreferrer">点击搜索</a></div>,
    //                         duration: 0
    //                     })
    //                     // document.getElementById('address').value = address;
    //                 } else {
    //                     message.error('根据经纬度查询地址失败')
    //                 }
    //             });
    //         })
    //     }
    // }, [geo])

    return (
        <>
            <TrackingSearchForm onFinish={onFinish} />
            <div>
                <div
                    id="container"
                    ref={mapContainer}
                    style={{ height: 500, width: '100%' }}
                ></div>
                
            </div>
        </>
    );
}