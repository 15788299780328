import React, {  useEffect, useState } from 'react'
import { Table, message, Button, Space, Drawer } from 'antd'
import { authGetData } from '../service';
import { useSelector } from 'react-redux';

export default function SalesmansPage(){
    const [loading, setLoading] = useState(false)
    const [drawerVisiable, setDrawerVisiable] = useState(false)
    const [data, setData] = useState([])
    const [drawerData, setDrawerData] = useState([])
    const user = useSelector(state=>state.user)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    
    let handleTableChange = (pagination) => {
        setPage(pagination.current)
    }

    function showTotal(total) {
        return `共 ${total} 条`;
    }

    const pagination = {
        total: total,
        showTotal: showTotal,
        pageSize: 20,
        showSizeChanger: false
    }

    const showRelateUser = (salesman_id)=>{
        authGetData(`/api/salesman/${salesman_id}/users`, user.token)
            .then(data => {
                setDrawerData(data.items)
                setDrawerVisiable(true)
            })
            .catch(p => {
                if (!p) return
                p.then(data => {
                    message.error(data.message)
                })
            })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '名称',
            dataIndex: 'username',
            render: (text, record, index) => (
                <Space>
                    <span>{record.username}</span>
                </Space>
            ),
        },
        {
            title: '手机号',
            dataIndex: 'mobile'
        },
        {
            title: '关联用户数',
            dataIndex: 'user_count'
        },
        {
            title: '关联用户',
            render: (text, record, index) => (
                <Button type="primary" onClick={showRelateUser.bind(this, record.id)}>查看关联列表</Button>
            ),
        }
    ];

    useEffect(()=>{
        setLoading(true)
        authGetData(`/api/salesman/page/${page}`, user.token)
            .then(data=>{
                setLoading(false)
                setData(data.items)
                setTotal(data.total)
            })
            .catch(p=>{
                setLoading(false)
                if (user.token === '') return
                p.then(data=>{
                    message.error(data.message)
                })
            })
    }, [page, user.token]);

    const drawerColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '用户名',
            dataIndex: 'username',
        },
        {
            title: '开户费',
            dataIndex: 'opening_fee',
        },
        {
            title: '开户日期',
            dataIndex: 'opening_date',
        },
    ];

    const drawerClose = ()=>{
        setDrawerVisiable(false)
    }

    return (
        <>
            <Table
                columns={columns}
                pagination={pagination}
                rowKey={record => record.id}
                dataSource={data}
                loading={loading}
                onChange={handleTableChange}
            />
            <Drawer
                width={550}
                title="关联列表"
                placement="right"
                onClose={drawerClose}
                visible={drawerVisiable}
            >
                <Table
                    size="small"
                    pagination={false}
                    columns={drawerColumns}
                    dataSource={drawerData}
                />
            </Drawer>
        </>
    )
}